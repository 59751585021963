<template>
  <section class="all-documents-view">
    <div class="vue-data-table-default">
      <div>
        <data-tables-server
          v-loading="configureLoading"
          :element-loading-text="configureLoadingMessage"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          :data="documents"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :pagination-props="paginationPropsForTable"
          :total="total"
          style="width: 100%"
          @query-change="loadData"
          @sort-change="sortChange"
          @change="loadData"
          v-if="!getIsMobile"
          @selection-change="handleSelectionChange"
          @size-change="handleSizeChange"
          :page-sizes="paginationProps"
        >
          <el-table-column
            type="selection"
            width="55"
            v-if="this.$route && this.$route.name != 'Dashboard'"
          >
          </el-table-column>
          <el-table-column
            label="Name"
            id="name"
            prop="title"
            min-width="230"
            fixed
            sortable="custom"
          >
            <template slot-scope="scope">
              <div class="icon-block">
                <div class="icon">
                  <div class="image-file" v-if="isImage(scope.row.file_type)">
                    <img
                      src="@/assets/img/icons/image-file.svg"
                      alt="icon"
                      class="img-fluid"
                    />
                  </div>
                  <div class="pdf-file" v-if="isPdf(scope.row.file_type)">
                    <img
                      src="@/assets/img/icons/pdf-file.svg"
                      alt="icon"
                      class="img-fluid"
                    />
                  </div>
                  <div
                    class="doc-file"
                    v-if="
                      !isImage(scope.row.file_type) &&
                      !isPdf(scope.row.file_type)
                    "
                  >
                    <img
                      src="@/assets/img/icons/pdf.svg"
                      alt="icon"
                      class="img-fluid"
                    />
                  </div>
                </div>
                <div class="icon-text fs-8">
                  <!-- <template v-if="scope.row.title">{{
                  scope.row.title
                }}</template>-->
                  <template v-if="scope.row.title">
                    <el-popover
                      placement="top-start"
                      width="200"
                      trigger="hover"
                      :content="scope.row.title"
                    >
                      <a
                        slot="reference"
                        class="doc-title"
                        @click="onEdit(scope.row)"
                      >
                        {{
                          getDocumentTitle(scope.row.title)
                            | truncate(16, "...")
                        }}
                      </a>
                    </el-popover>
                    <div>
                      <span v-if="getDocumentUsers(scope.row)">
                        <span
                          v-if="
                            !getDocumentUsersTO(scope.row) &&
                            !getDocumentUsers(scope.row)
                          "
                        >
                          <p class="doc-sub">No Recipients</p>
                        </span>
                        <div
                          class="doc-sub"
                          v-for="(user, index) in scope.row.document_users"
                          :key="index"
                        >
                          <p
                            v-if="
                              index < limit &&
                              user.type != 'SENDER' &&
                              user.first_name &&
                              user.last_name &&
                              scope.row.document_users.length != 1 &&
                              user.company_name &&
                              scope.row.created_by === authid
                            "
                          >
                            <span class="d-flex">
                              {{
                                "To:" + user.first_name + " " + user.last_name
                              }}
                            </span>
                            <!-- <br /> -->
                            <!-- {{ "Company:" + user.company_name }} -->
                            <span
                              class="d-flex"
                              v-if="
                                user.entity_data_id &&
                                user.entity_data_id.entity_prime_data
                              "
                            >
                              {{
                                "Company:" +
                                user.entity_data_id.entity_prime_data[
                                  Object.keys(
                                    user.entity_data_id.entity_prime_data
                                  )[0]
                                ]
                              }}
                            </span>
                            <span v-else>
                              {{ "Company:" + user.company_name }}
                            </span>
                          </p>

                          <p
                            class="d-flex"
                            v-else-if="
                              index < limit &&
                              user.type != 'SENDER' &&
                              user.first_name &&
                              user.last_name &&
                              scope.row.document_users.length != 1 &&
                              scope.row.created_by === authid
                            "
                          >
                            {{ "To:" + user.first_name + " " + user.last_name }}
                          </p>
                          <p
                            v-if="
                              (!user.first_name || !user.last_name) &&
                              user.type != 'SENDER'
                            "
                          >
                            Users not assigned
                          </p>
                          <!--This is for receiver if the documents send by sender-->
                          <p
                            v-if="
                              user.type == 'SENDER' &&
                              scope.row.created_by != authid &&
                              user.company_name &&
                              user.company_name.length
                            "
                          >
                            From:{{ user.first_name + " " + user.last_name
                            }}<br />
                            {{ "Company:" + user.company_name }}
                          </p>
                          <p
                            v-else-if="
                              user.type == 'SENDER' &&
                              scope.row.created_by != authid
                            "
                          >
                            From:{{ user.first_name + " " + user.last_name }}
                          </p>
                          <p
                            v-if="
                              scope.row.created_by === authid &&
                              scope.row.document_users.length == 1 &&
                              user.type == 'SENDER'
                            "
                          >
                            To:{{ "Only me" }}
                          </p>
                        </div>
                        <el-dropdown>
                          <span class="el-dropdown-link">
                            <el-link
                              class="doc-sub"
                              :value="scope.row.document_users.length"
                              v-if="
                                scope.row.document_users.length > 2 &&
                                scope.row.created_by === authid
                              "
                            >
                              +{{ scope.row.document_users.length - 2 }} more
                            </el-link>
                          </span>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                              v-for="(user, index) in scope.row.document_users"
                              :key="index"
                            >
                              <div
                                class="d-flex doc-sub"
                                v-if="user.type == 'RECEIVER'"
                              >
                                <p class="doc-sub" v-if="user.company_name">
                                  {{ user.first_name + " " + user.last_name }}
                                  <el-divider direction="vertical"></el-divider>
                                  {{ "Company:" + user.company_name }}
                                </p>
                                <p class="doc-sub" v-else>
                                  {{ user.first_name + " " + user.last_name }}
                                </p>
                              </div>
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                      </span>
                    </div>
                    <!-- <span v-if="getDocumentUsersTO(scope.row)">
                    <p class="doc-sub">
                      To : {{ getDocumentUsersTO(scope.row) }}
                    </p>
                  </span> -->

                    <!-- <span
                    v-if="
                      !getDocumentUsersTO(scope.row) &&
                      !getDocumentUsers(scope.row)
                    "
                  >
                    <p class="doc-sub">No Recipients</p>
                  </span> -->
                  </template>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="action"
            class="action"
            min-width="185"
            width="250"
            fixed="left"
          >
            <template slot-scope="scope">
              <ul class="action-buttons hover-buttons">
                <!-- <li v-if="scope.row.type == 'CUSTOM'">
                <el-button
                  plain
                  size="mini"
                  type="primary"
                  class="px-2"
                  @click="onConfigure(scope.row)"
                  title="Configure Document"
                >
                  <i class="el-icon-view"></i>
                </el-button>
              </li> -->
                <el-tooltip
                  v-if="
                    checkPermissionForDocument(scope.row, 'viewDocument') &&
                    (scope.row.document_status == 'SENT' ||
                      scope.row.document_status == 'APPROVED') &&
                    !waitingForMe(scope.row)
                  "
                  content="View Document"
                >
                  <li
                    v-if="
                      (scope.row.document_status == 'SENT' ||
                        scope.row.document_status == 'APPROVED') &&
                      !waitingForMe(scope.row)
                    "
                  >
                    <el-button
                      plain
                      type="danger"
                      size="mini"
                      @click="onEdit(scope.row)"
                    >
                      <i class="el-icon-view"></i>
                    </el-button>
                  </li>
                </el-tooltip>
                <el-tooltip
                  v-else-if="
                    (checkPermissionForDocument(scope.row, 'signDocuments') &&
                      (scope.row.document_status == 'SENT' ||
                        scope.row.document_status == 'APPROVED') &&
                      waitingForMe(scope.row)) ||
                    (waitingForMe(scope.row) &&
                      isInDocumentUsers(scope.row.document_users))
                  "
                  content="Sign Document"
                >
                  <li
                    v-if="
                      (scope.row.document_status == 'SENT' ||
                        scope.row.document_status == 'APPROVED') &&
                      waitingForMe(scope.row)
                    "
                  >
                    <el-button
                      plain
                      type="danger"
                      size="mini"
                      @click="onEdit(scope.row)"
                    >
                      <i class="el-icon-edit-outline"></i>
                    </el-button>
                  </li>
                </el-tooltip>
                <el-tooltip
                  v-else-if="
                    (checkPermissionForDocument(
                      scope.row,
                      'downloadDocument'
                    ) &&
                      scope.row.document_status == 'COMPLETED') ||
                    (scope.row.document_status == 'COMPLETED' &&
                      isInDocumentUsers(scope.row.document_users))
                  "
                  content=" Download Document"
                >
                  <li v-if="scope.row.document_status == 'COMPLETED'">
                    <el-dropdown v-if="isDocumentHasAttachment(scope.row)">
                      <el-button plain type="danger" size="mini" class="px-2">
                        <i class="el-icon-paperclip"></i>
                      </el-button>
                      <!-- <el-button
                      plain
                      type="danger"
                      size="mini"
                    >
                      <i class="el-icon-paperclip"></i>
                    </el-button> -->
                      <el-dropdown-menu slot="dropdown">
                        <a
                          v-for="(file, index) in attachmentsFiles(scope.row)"
                          :key="index"
                          @click="gotoAttachment(file)"
                        >
                          <el-dropdown-item>{{ file.title }}</el-dropdown-item>
                        </a>
                        <a
                          v-if="
                            checkPermissionForDocument(
                              scope.row,
                              'downloadDocument'
                            )
                          "
                          @click="gotoMainDocument(scope.row)"
                        >
                          <el-dropdown-item style="color: red">{{
                            scope.row.title + " *"
                          }}</el-dropdown-item>
                        </a>

                        <a
                          v-if="
                            (checkPermissionForDocument(
                              scope.row,
                              'downloadDocument'
                            ) &&
                              checkPermissionForDocument(
                                scope.row,
                                'downloadOriginal'
                              )) ||
                            isInDocumentUsers(scope.row.document_users)
                          "
                          @click="downloadAlldocuments(scope.row)"
                        >
                          <el-dropdown-item>Download All</el-dropdown-item>
                        </a>
                      </el-dropdown-menu>
                    </el-dropdown>
                    <el-dropdown>
                      <el-button style="margin-right: 10px" size="mini">
                        <i class="el-icon-download"></i>
                      </el-button>
                      <el-dropdown-menu>
                        <a @click="enableDownloadSingleDocument(scope.row)">
                          <el-dropdown-item>
                            <i class="el-icon-folder-opened"></i>
                            To Device
                          </el-dropdown-item>
                        </a>
                        <a @click="downloadToSharepoint(scope.row)">
                          <el-dropdown-item>
                            <i class="el-icon-share"></i>
                            To SharePoint
                          </el-dropdown-item>
                        </a>
                        <a @click="downloadToOneDrive(scope.row)">
                          <el-dropdown-item>
                            <i class="el-icon-cloudy-and-sunny"></i>
                            To OneDrive
                            <el-button
                              size="mini"
                              v-if="
                                oneDriveData.accessToken &&
                                oneDriveData.expiresIn >
                                  new Date().toISOString()
                              "
                              type="text"
                              class="logout-button"
                              @click.stop="logoutOneDrive"
                            >
                              <span
                                style="
                                  display: inline-block;
                                  vertical-align: middle;
                                "
                              >
                                <img
                                  src="@/assets/img/icons/Logout.svg"
                                  alt="Icon"
                                  class="img-fluid icon-svg"
                                />
                              </span>
                            </el-button>
                            <!-- <el-button v-if="oneDriveData.accessToken && (oneDriveData.expiresIn > new Date().toISOString())" type="text" icon="el-icon-switch-button" class="logout-button" @click.stop="logoutOneDrive"></el-button> -->
                          </el-dropdown-item>
                        </a>
                        <!-- <a @click="downloadToGoogleDrive(scope.row)">
                          <el-dropdown-item>
                            <i class="el-icon-upload2"></i>
                            To GoogleDrive
                          </el-dropdown-item>
                          </a> -->
                      </el-dropdown-menu>
                    </el-dropdown>
                    <!-- <el-button
                      v-else
                      plain
                      type="danger"
                      size="mini"
                      class="px-2"
                      @click="onDownload(scope.row)"
                    >
                      <i class="el-icon-download"></i>
                    </el-button> -->
                  </li>
                </el-tooltip>
                <el-tooltip
                  v-else-if="
                    checkPermissionForDocument(scope.row, 'deleteDocument') &&
                    (scope.row.document_status == 'VOIDED' ||
                      scope.row.document_status == 'EXPIRED') &&
                    scope.row.created_by === authid
                  "
                  content="Delete Document"
                >
                  <li
                    v-if="
                      (scope.row.document_status == 'VOIDED' ||
                        scope.row.document_status == 'EXPIRED') &&
                      scope.row.created_by === authid
                    "
                  >
                    <el-button
                      plain
                      type="danger"
                      size="mini"
                      class="px-2"
                      @click="archiveDocument(scope.row._id)"
                    >
                      <i class="el-icon-delete"></i>
                    </el-button>
                  </li>
                </el-tooltip>
                <el-tooltip
                  v-else-if="
                    scope.row.document_status == 'DECLINED' &&
                    scope.row.created_by === authid
                  "
                  content="Void Document"
                >
                  <li
                    style="margin-left: -20px"
                    v-if="
                      scope.row.document_status == 'DECLINED' &&
                      scope.row.created_by === authid
                    "
                  >
                    <el-button
                      plain
                      type="danger"
                      size="mini"
                      @click="alertvoided(scope.row._id)"
                    >
                      <i class="el-icon-remove-outline"></i>
                    </el-button>
                  </li>
                </el-tooltip>
                <el-tooltip
                  v-else-if="
                    checkPermissionForDocument(scope.row, 'editDraft') &&
                    scope.row.document_status == 'DRAFT'
                  "
                  content="Edit Document"
                >
                  <li v-if="scope.row.document_status == 'DRAFT'">
                    <el-button
                      plain
                      type="danger"
                      size="mini"
                      @click="onEdit(scope.row)"
                    >
                      <i class="el-icon-edit"></i>
                    </el-button>
                  </li>
                </el-tooltip>
                <li style="margin-left: -20px" v-if="showActions(scope.row)">
                  <el-dropdown>
                    <el-button
                      style="margin-right: 10px"
                      size="mini"
                      class="el-dropdown-link"
                    >
                      Actions
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <a @click="onEdit(scope.row)">
                        <el-dropdown-item
                          v-if="
                            (scope.row.created_by === authid ||
                              checkPermissionForDocument(
                                scope.row,
                                'companyUser'
                              )) &&
                            scope.row.document_status == 'DRAFT' &&
                            scope.row.document_status != 'VOIDED' &&
                            scope.row.document_status != 'EXPIRED'
                          "
                          @click="
                            handleSendDocumentToUsers(scope.row, {
                              openSendDocumentsModal: true,
                            })
                          "
                          ><i class="el-icon-position"></i>Send
                          Document</el-dropdown-item
                        >
                      </a>
                      <a
                        @click="correctDocument(scope.row, scope.row._id)"
                        v-if="
                          ((checkPermissionForDocument(
                            scope.row,
                            'correctDocument'
                          ) ||
                            scope.row.created_by._id === authid) &&
                            scope.row.document_status != 'DRAFT' &&
                            scope.row.document_status != 'VOIDED' &&
                            scope.row.document_status != 'DECLINED' &&
                            scope.row.document_status != 'COMPLETED' &&
                            scope.row.document_status != 'ARCHIVED' &&
                            scope.row.document_status != 'EXPIRED') ||
                          isfilledOrNot(scope.row)
                        "
                      >
                        <el-dropdown-item
                          ><i class="el-icon-document-checked"></i> Correct
                          Document</el-dropdown-item
                        >
                      </a>
                      <a
                        v-if="
                          (scope.row.created_by === authid ||
                            checkPermissionForDocument(
                              scope.row,
                              'resendDocument'
                            )) &&
                          scope.row.document_status != 'VOIDED' &&
                          scope.row.document_status != 'COMPLETED' &&
                          scope.row.document_status != 'ARCHIVED' &&
                          scope.row.document_status != 'EXPIRED' &&
                          scope.row.document_status != 'DRAFT' &&
                          scope.row.document_status != 'DECLINED'
                        "
                        @click="ResendDocument(scope.row)"
                      >
                        <el-dropdown-item
                          ><i class="el-icon-s-promotion"></i> Resend Document
                        </el-dropdown-item>
                      </a>
                      <a
                        v-if="
                          checkPermissionForDocument(
                            scope.row,
                            'saveTemplate'
                          ) && scope.row.document_status != 'ARCHIVED'
                        "
                        @click="saveAsTemplate(scope.row)"
                      >
                        <el-dropdown-item
                          ><i class="el-icon-folder-add"></i> Save As
                          Template</el-dropdown-item
                        >
                      </a>
                      <a
                        @click="shareCompletedDocument(scope.row)"
                        v-if="
                          checkPermissionForDocument(scope.row, 'shareUsers') &&
                          scope.row.document_status == 'COMPLETED'
                        "
                      >
                        <el-dropdown-item
                          ><i class="el-icon-share"></i> Share With
                          Users</el-dropdown-item
                        >
                      </a>
                      <a
                        @click="alertvoided(scope.row._id)"
                        v-if="
                          checkPermissionForDocument(
                            scope.row,
                            'voidDocument'
                          ) &&
                          scope.row.document_status != 'VOIDED' &&
                          scope.row.document_status != 'COMPLETED' &&
                          scope.row.document_status != 'DRAFT' &&
                          scope.row.document_status != 'ARCHIVED'
                        "
                      >
                        <el-dropdown-item
                          ><i class="el-icon-remove-outline"></i> Void
                          Document</el-dropdown-item
                        >
                      </a>
                      <a
                        @click="EditVoidDocument(scope.row, scope.row._id)"
                        v-if="
                          (checkPermissionForDocument(
                            scope.row,
                            'cloneDocument'
                          ) ||
                            scope.row.created_by === authid) &&
                          (scope.row.document_status == 'VOIDED' ||
                            scope.row.document_status == 'COMPLETED')
                        "
                      >
                        <el-dropdown-item
                          ><i class="el-icon-document-copy"></i> Clone
                          Document</el-dropdown-item
                        >
                      </a>
                      <!-- <a
                        @click="
                          openNameModal(
                            scope.row._id,
                            scope.row.title,
                            'duplicate'
                          )
                        "
                        v-if="
                          checkPermissionForDocument(
                            scope.row,
                            'cloneDocument'
                          ) && scope.row.document_status == 'DRAFT'
                        "
                      >
                        <el-dropdown-item
                          ><i class="el-icon-copy-document"></i>
                          Duplicate</el-dropdown-item
                        >
                      </a> -->

                      <a
                        v-if="
                          checkPermissionForDocument(
                            scope.row,
                            'renameDocument'
                          ) && scope.row.document_status != 'VOIDED'
                        "
                        @click="
                          openNameModal(
                            scope.row._id,
                            scope.row.title,
                            'Rename'
                          )
                        "
                      >
                        <el-dropdown-item
                          ><i class="el-icon-edit-outline"></i> Rename
                          Document</el-dropdown-item
                        >
                      </a>
                      <a
                        v-if="
                          (scope.row &&
                            scope.row.document_status === 'SENT' &&
                            isInDocumentUsers(scope.row.document_users) &&
                            scope.row.configurable_document_data &&
                            !scope.row.configurable_document_data
                              .is_ck_editor_document) ||
                          (checkPermissionForDocument(
                            scope.row,
                            'downloadOriginal'
                          ) &&
                            scope.row.configurable_document_data &&
                            !scope.row.configurable_document_data
                              .is_ck_editor_document)
                        "
                        @click="originalDownload(scope.row)"
                      >
                        <el-tooltip
                          content="You can download the original document uploaded by 'Document Admin'"
                          placement="left-end"
                        >
                          <el-dropdown-item class="sendEmailLink"
                            ><i class="el-icon-download ml-1"></i>Download
                            Original</el-dropdown-item
                          >
                        </el-tooltip>
                      </a>
                      <a
                        @click="settings(scope.row._id)"
                        v-if="
                          (scope.row.created_by === authid ||
                            checkPermissionForDocument(
                              scope.row,
                              'documentSettings'
                            )) &&
                          scope.row.document_status != 'COMPLETED' &&
                          scope.row.document_status != 'VOIDED' &&
                          scope.row.document_status != 'EXPIRED' &&
                          scope.row.document_status != 'ARCHIVED'
                        "
                      >
                        <el-dropdown-item
                          ><i class="el-icon-setting"></i>Document
                          Settings</el-dropdown-item
                        >
                      </a>
                      <a
                        v-if="
                          (checkPermissionForDocument(
                            scope.row,
                            'downloadDocument'
                          ) &&
                            scope.row.document_status == 'COMPLETED') ||
                          (scope.row.document_status &&
                            scope.row.document_status == 'COMPLETED' &&
                            isInDocumentUsers(scope.row.document_users))
                        "
                        @click="enableDownloadSingleDocument(scope.row)"
                      >
                        <el-dropdown-item
                          ><i class="el-icon-download"></i>Download
                          Document</el-dropdown-item
                        >
                      </a>
                      <!--<el-dropdown-item
                        v-if="
                          scope.row.document_status != 'VOIDED' &&
                          scope.row.created_by === authid
                        "
                        >Copy Link to share document</el-dropdown-item
                      >-->
                      <a
                        @click="archiveDocument(scope.row._id)"
                        v-if="
                          checkPermissionForDocument(
                            scope.row,
                            'deleteDocument'
                          ) && scope.row.document_status != 'ARCHIVED'
                        "
                      >
                        <el-dropdown-item
                          ><i class="el-icon-delete"></i>Delete
                          Document</el-dropdown-item
                        >
                      </a>
                      <a
                        @click="archiveDocumentundo(scope.row._id)"
                        v-if="
                          scope.row.created_by === authid &&
                          scope.row.document_status === 'ARCHIVED'
                        "
                      >
                        <el-dropdown-item
                          ><i class="el-icon-refresh-left"></i> Undo
                          Delete</el-dropdown-item
                        >
                      </a>
                      <a
                        @click="permanentDocument(scope.row._id)"
                        v-if="
                          checkPermissionForDocument(
                            scope.row,
                            'deleteDocument'
                          ) && scope.row.document_status === 'ARCHIVED'
                        "
                      >
                        <el-dropdown-item
                          ><i class="el-icon-delete-solid"></i> Permanent
                          Delete</el-dropdown-item
                        >
                      </a>

                      <a
                        v-if="
                          (scope.row.created_by === authid ||
                            checkPermissionForDocument(
                              scope.row,
                              'sendToMail'
                            )) &&
                          scope.row.document_status == 'COMPLETED'
                        "
                        @click="sendToMail(scope.row._id, scope.row.name)"
                      >
                        <el-tooltip
                          content="Document owner only can perofrm this action"
                          placement="left-end"
                        >
                          <el-dropdown-item class="sendEmailLink"
                            ><i class="el-icon-position pl-2"></i> Send To
                            Mail</el-dropdown-item
                          >
                        </el-tooltip>
                      </a>
                    </el-dropdown-menu>
                  </el-dropdown>
                </li>
              </ul>
            </template>
          </el-table-column>
          <el-table-column
            prop="document_tracking_status.description"
            label="Current Status"
            min-width="200"
          >
            <template slot-scope="scope">
              <el-progress
                :percentage="getSignatureStatus(scope.row)"
                :format="format"
                v-if="scope.row.document_status == 'SENT'"
              >
              </el-progress>

              <p class="doc-subs" v-if="scope.row.document_status == 'SENT'">
                {{ getdoument(scope.row) }}
              </p>

              <el-popover
                placement="top-start"
                :title="getWaitingUser(scope.row)"
                width="200"
                trigger="hover"
                v-if="scope.row.document_status == 'SENT'"
              >
                <span style="font-size: 10px">{{
                  getCurrentRequestedUser(scope.row)
                }}</span>
                <p slot="reference" class="text-secondary-sent">
                  {{
                    scope.row.document_tracking_status &&
                    scope.row.document_tracking_status.description
                      ? scope.row.document_tracking_status.description
                      : ""
                  }}
                </p>
              </el-popover>
              <p
                slot="reference"
                class="text-secondary"
                v-if="
                  scope.row.document_status != 'SENT' &&
                  scope.row.document_status != 'VOIDED' &&
                  scope.row.document_status != 'EXPIRED' &&
                  scope.row.document_status != 'ARCHIVED'
                "
              >
                {{
                  scope.row.document_tracking_status &&
                  scope.row.document_tracking_status.description
                    ? scope.row.document_tracking_status.description
                    : ""
                }}
              </p>
              <p
                slot="reference"
                class="text-secondary"
                v-if="scope.row.document_status == 'VOIDED'"
              >
                Document Voided
              </p>
              <p
                slot="reference"
                class="text-secondary"
                v-if="scope.row.document_status == 'EXPIRED'"
              >
                Document Expired
              </p>
              <p
                slot="reference"
                class="text-secondary"
                v-if="scope.row.document_status == 'ARCHIVED'"
              >
                Document Archived
              </p>
            </template>
          </el-table-column>
          <el-table-column
            label="Status"
            id="category"
            prop="document_status"
            min-width="150"
            sortable="custom"
          >
            <template slot-scope="scope">
              <div class="check-font">
                <span
                  v-if="
                    scope &&
                    scope.row &&
                    scope.row.document_status &&
                    scope.row.document_status == 'SENT'
                  "
                  :class="scope.row.document_status.toLowerCase() + ' circle'"
                >
                  Sent
                </span>
                <span
                  v-if="
                    scope &&
                    scope.row &&
                    scope.row.document_status &&
                    scope.row.document_status == 'COMPLETED'
                  "
                  :class="scope.row.document_status.toLowerCase() + ' circle'"
                  >Completed</span
                >
                <span
                  v-if="
                    scope &&
                    scope.row &&
                    scope.row.document_status &&
                    scope.row.document_status == 'ARCHIVED'
                  "
                  :class="scope.row.document_status.toLowerCase() + ' circle'"
                  >Archived</span
                >
                <span
                  v-if="
                    scope &&
                    scope.row &&
                    scope.row.document_status &&
                    scope.row.document_status == 'VOIDED'
                  "
                  :class="scope.row.document_status.toLowerCase() + ' circle'"
                  >Voided</span
                >
                <span
                  v-if="
                    scope &&
                    scope.row &&
                    scope.row.document_status &&
                    scope.row.document_status == 'APPROVED'
                  "
                  :class="scope.row.document_status.toLowerCase() + ' circle'"
                  >Approved</span
                >
                <span
                  v-if="
                    scope &&
                    scope.row &&
                    scope.row.document_status &&
                    scope.row.document_status == 'DECLINED'
                  "
                  :class="scope.row.document_status.toLowerCase() + ' circle'"
                  >Declined</span
                >
                <span
                  v-if="
                    scope &&
                    scope.row &&
                    scope.row.document_status &&
                    scope.row.document_status == 'EXPIRED'
                  "
                  :class="scope.row.document_status.toLowerCase() + ' circle'"
                  >Expired</span
                >
                <span
                  v-if="
                    scope &&
                    scope.row &&
                    scope.row.document_status &&
                    scope.row.document_status == 'DRAFT'
                  "
                  :class="scope.row.document_status.toLowerCase() + ' circle'"
                  >Draft</span
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="Last Modified"
            width="200"
            prop="updated_at"
            sortable="custom"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.updated_at">
                <span class="d-inline-block pr-2 text-muted">
                  {{ scope.row.updated_at | globalDateTimeFormat }}
                </span>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            label="Created at"
            width="200"
            prop="created_at"
            sortable="custom"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.created_at">
                <span class="d-inline-block pr-2 text-muted">
                  {{ scope.row.created_at | globalDateTimeFormat }}
                </span>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            label="Expires at"
            width="200"
            prop="expires_at"
            sortable="custom"
          >
            <template slot-scope="scope">
              <template
                v-if="
                  scope.row.document_status == 'SENT' ||
                  scope.row.document_status == 'APPROVED'
                "
              >
                <span class="d-inline-block pr-2 text-muted">
                  {{ scope.row.expires_at | globalDateTimeFormat }}
                </span>
              </template>
            </template>
          </el-table-column>
        </data-tables-server>
        <el-table
          v-loading="configureLoading"
          :header-cell-style="{ background: 'var(--lighter-primary-color)' }"
          element-loading-text="Documents Loading..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          :data="documents"
          style="width: 100%"
          v-if="getIsMobile"
        >
          <el-table-column type="expand">
            <template slot-scope="scope">
              <div class="expand-column-item">
                <div class="expand-column-item-label">Current Status:</div>
                <div class="expand-column-item-content">
                  <el-progress
                    :percentage="getSignatureStatus(scope.row)"
                    :format="format"
                    v-if="scope.row.document_status == 'SENT'"
                  >
                  </el-progress>

                  <p
                    class="doc-subs"
                    v-if="scope.row.document_status == 'SENT'"
                  >
                    {{ getdoument(scope.row) }}
                  </p>

                  <el-popover
                    placement="top-start"
                    :title="getWaitingUser(scope.row)"
                    width="200"
                    trigger="hover"
                    v-if="scope.row.document_status == 'SENT'"
                  >
                    <span style="font-size: 10px">{{
                      getCurrentRequestedUser(scope.row)
                    }}</span>
                    <p slot="reference" class="text-secondary-sent">
                      {{
                        scope.row.document_tracking_status &&
                        scope.row.document_tracking_status.description
                          ? scope.row.document_tracking_status.description
                          : ""
                      }}
                    </p>
                  </el-popover>
                  <p
                    slot="reference"
                    class="text-secondary"
                    v-if="scope.row.document_status != 'SENT'"
                  >
                    {{
                      scope.row.document_tracking_status &&
                      scope.row.document_tracking_status.description
                        ? scope.row.document_tracking_status.description
                        : ""
                    }}
                  </p>
                </div>
              </div>
              <div class="expand-column-item">
                <div class="expand-column-item-label">Status:</div>
                <div class="expand-column-item-content">
                  <span
                    :class="scope.row.document_status.toLowerCase() + ' circle'"
                    >{{ scope.row.document_status }}</span
                  >
                </div>
              </div>
              <div class="expand-column-item">
                <div class="expand-column-item-label">Last Modified:</div>
                <div class="expand-column-item-content">
                  <template v-if="scope.row.updated_at">
                    <span class="fs-8 d-inline-block pr-2 text-muted">
                      {{ scope.row.updated_at | globalDateTimeFormat }}
                    </span>
                  </template>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="Name"
            id="name"
            min-width="150"
            sortable="custom"
            fixed
          >
            <template slot-scope="scope">
              <div class="icon-block">
                <div class="icon">
                  <div class="image-file" v-if="isImage(scope.row.file_type)">
                    <img
                      src="@/assets/img/icons/image-file.svg"
                      alt="icon"
                      class="img-fluid"
                    />
                  </div>
                  <div class="pdf-file" v-if="isPdf(scope.row.file_type)">
                    <img
                      src="@/assets/img/icons/pdf-file.svg"
                      alt="icon"
                      class="img-fluid"
                    />
                  </div>
                  <div
                    class="doc-file"
                    v-if="
                      !isImage(scope.row.file_type) &&
                      !isPdf(scope.row.file_type)
                    "
                  >
                    <img
                      src="@/assets/img/icons/pdf.svg"
                      alt="icon"
                      class="img-fluid"
                    />
                  </div>
                </div>
                <div class="icon-text fs-8">
                  <!-- <template v-if="scope.row.title">{{
                    scope.row.title
                  }}</template>-->
                  <template v-if="scope.row.title">
                    <a class="doc-title" @click="onEdit(scope.row)">
                      {{ getDocumentTitle(scope.row.title) }}
                    </a>

                    <span v-if="getDocumentUsers(scope.row)">
                      <p class="doc-sub">
                        {{ getDocumentUsers(scope.row) }}
                      </p>
                    </span>
                  </template>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="action"
            class="action"
            min-width="135"
            fixed="left"
          >
            <template slot-scope="scope">
              <ul class="action-buttons hover-buttons">
                <div style="display: flex">
                  <div>
                    <el-tooltip
                      v-if="
                        checkPermissionForDocument(scope.row, 'viewDocument') &&
                        (scope.row.document_status == 'SENT' ||
                          scope.row.document_status == 'APPROVED') &&
                        !waitingForMe(scope.row)
                      "
                      content="View Document"
                    >
                      <li
                        style="margin-right: -10px"
                        v-if="
                          (scope.row.document_status == 'SENT' ||
                            scope.row.document_status == 'APPROVED') &&
                          !waitingForMe(scope.row)
                        "
                      >
                        <el-button
                          plain
                          type="danger"
                          size="mini"
                          class="px-2"
                          @click="onEdit(scope.row)"
                        >
                          <i class="el-icon-view"></i>
                        </el-button>
                      </li>
                    </el-tooltip>
                    <el-tooltip
                      v-if="
                        (checkPermissionForDocument(
                          scope.row,
                          'signDocuments'
                        ) &&
                          (scope.row.document_status == 'SENT' ||
                            scope.row.document_status == 'APPROVED') &&
                          waitingForMe(scope.row)) ||
                        (waitingForMe(scope.row) &&
                          isInDocumentUsers(scope.row.document_users))
                      "
                      content="Sign Document"
                    >
                      <li
                        style="margin-right: -10px"
                        v-if="
                          (scope.row.document_status == 'SENT' ||
                            scope.row.document_status == 'APPROVED') &&
                          waitingForMe(scope.row)
                        "
                      >
                        <el-button
                          plain
                          type="danger"
                          size="mini"
                          class="px-2"
                          @click="onEdit(scope.row)"
                        >
                          <i class="el-icon-edit-outline"></i>
                        </el-button>
                      </li>
                    </el-tooltip>
                    <el-tooltip
                      v-if="
                        (checkPermissionForDocument(
                          scope.row,
                          'downloadDocument'
                        ) &&
                          scope.row.document_status == 'COMPLETED') ||
                        (scope.row.document_status == 'COMPLETED' &&
                          isInDocumentUsers(scope.row.document_users))
                      "
                      content=" Download Document"
                    >
                      <li
                        style="margin-right: -10px"
                        v-if="scope.row.document_status == 'COMPLETED'"
                      >
                        <el-dropdown v-if="isDocumentHasAttachment(scope.row)">
                          <el-button
                            plain
                            type="danger"
                            size="mini"
                            class="px-2"
                          >
                            <i class="el-icon-paperclip"></i>
                          </el-button>
                          <!-- <el-button
                      plain
                      type="danger"
                      size="mini"
                    >
                      <i class="el-icon-paperclip"></i>
                    </el-button> -->
                          <el-dropdown-menu slot="dropdown">
                            <a
                              v-for="(file, index) in attachmentsFiles(
                                scope.row
                              )"
                              :key="index"
                              @click="gotoAttachment(file)"
                            >
                              <el-dropdown-item>{{
                                file.title
                              }}</el-dropdown-item>
                            </a>
                            <a
                              v-if="
                                checkPermissionForDocument(
                                  scope.row,
                                  'downloadDocument'
                                )
                              "
                              @click="gotoMainDocument(scope.row)"
                            >
                              <el-dropdown-item style="color: red">{{
                                scope.row.title + " *"
                              }}</el-dropdown-item>
                            </a>
                            <a
                              v-if="
                                (checkPermissionForDocument(
                                  scope.row,
                                  'downloadDocument'
                                ) &&
                                  checkPermissionForDocument(
                                    scope.row,
                                    'downloadOriginal'
                                  )) ||
                                isInDocumentUsers(scope.row.document_users)
                              "
                              @click="downloadAlldocuments(scope.row)"
                            >
                              <el-dropdown-item>Download All</el-dropdown-item>
                            </a>
                          </el-dropdown-menu>
                        </el-dropdown>
                        <el-button
                          v-else
                          plain
                          type="danger"
                          size="mini"
                          class="px-2"
                          @click="onDownload(scope.row)"
                        >
                          <i class="el-icon-download"></i>
                        </el-button>
                      </li>
                    </el-tooltip>
                    <el-tooltip
                      v-if="
                        checkPermissionForDocument(
                          scope.row,
                          'deleteDocument'
                        ) &&
                        (scope.row.document_status == 'VOIDED' ||
                          scope.row.document_status == 'EXPIRED') &&
                        scope.row.created_by === authid
                      "
                      content="Delete Document"
                    >
                      <li
                        style="margin-right: -10px"
                        v-if="
                          (scope.row.document_status == 'VOIDED' ||
                            scope.row.document_status == 'EXPIRED') &&
                          scope.row.created_by === authid
                        "
                      >
                        <el-button
                          plain
                          type="danger"
                          size="mini"
                          class="px-2"
                          @click="archiveDocument(scope.row._id)"
                        >
                          <i class="el-icon-delete"></i>
                        </el-button>
                      </li>
                    </el-tooltip>
                    <el-tooltip
                      v-if="
                        scope.row.document_status == 'DECLINED' &&
                        scope.row.created_by === authid
                      "
                      content="Void Document"
                    >
                      <li
                        style="margin-right: -10px"
                        v-if="
                          scope.row.document_status == 'DECLINED' &&
                          scope.row.created_by === authid
                        "
                      >
                        <el-button
                          plain
                          type="danger"
                          size="mini"
                          class="px-2"
                          @click="alertvoided(scope.row._id)"
                        >
                          <i class="el-icon-remove-outline"></i>
                        </el-button>
                      </li>
                    </el-tooltip>
                    <el-tooltip
                      v-if="
                        checkPermissionForDocument(scope.row, 'editDraft') &&
                        scope.row.document_status == 'DRAFT'
                      "
                      content="Edit Document"
                    >
                      <li
                        v-if="scope.row.document_status == 'DRAFT'"
                        style="margin-right: -10px"
                      >
                        <el-button
                          plain
                          type="danger"
                          size="mini"
                          class="px-2"
                          @click="onEdit(scope.row)"
                        >
                          <i class="el-icon-edit"></i>
                        </el-button>
                      </li>
                    </el-tooltip>
                  </div>
                  <div>
                    <li>
                      <el-dropdown>
                        <el-button
                          style="margin-right: 10px"
                          size="mini"
                          class="el-dropdown-link"
                        >
                          Actions
                          <i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                          <a @click="onEdit(scope.row)">
                            <el-dropdown-item
                              v-if="
                                (scope.row.created_by === authid ||
                                  checkPermissionForDocument(
                                    scope.row,
                                    'companyUser'
                                  )) &&
                                scope.row.document_status == 'DRAFT' &&
                                scope.row.document_status != 'VOIDED' &&
                                scope.row.document_status != 'EXPIRED'
                              "
                              @click="
                                handleSendDocumentToUsers(scope.row, {
                                  openSendDocumentsModal: true,
                                })
                              "
                              ><i class="el-icon-position"></i>Send
                              Document</el-dropdown-item
                            >
                          </a>
                          <a
                            @click="correctDocument(scope.row, scope.row._id)"
                            v-if="
                              ((checkPermissionForDocument(
                                scope.row,
                                'correctDocument'
                              ) ||
                                scope.row.created_by._id === authid) &&
                                scope.row.document_status != 'DRAFT' &&
                                scope.row.document_status != 'VOIDED' &&
                                scope.row.document_status != 'DECLINED' &&
                                scope.row.document_status != 'COMPLETED' &&
                                scope.row.document_status != 'ARCHIVED' &&
                                scope.row.document_status != 'EXPIRED') ||
                              isfilledOrNot(scope.row)
                            "
                          >
                            <el-dropdown-item
                              ><i class="el-icon-document-checked"></i> Correct
                              Document</el-dropdown-item
                            >
                          </a>
                          <a
                            v-if="
                              (scope.row.created_by === authid ||
                                checkPermissionForDocument(
                                  scope.row,
                                  'resendDocument'
                                )) &&
                              scope.row.document_status != 'VOIDED' &&
                              scope.row.document_status != 'COMPLETED' &&
                              scope.row.document_status != 'ARCHIVED' &&
                              scope.row.document_status != 'EXPIRED' &&
                              scope.row.document_status != 'DRAFT' &&
                              scope.row.document_status != 'DECLINED'
                            "
                            @click="ResendDocument(scope.row)"
                          >
                            <el-dropdown-item
                              ><i class="el-icon-s-promotion"></i> Resend
                              Document
                            </el-dropdown-item>
                          </a>
                          <a
                            v-if="
                              checkPermissionForDocument(
                                scope.row,
                                'saveTemplate'
                              ) && scope.row.document_status != 'ARCHIVED'
                            "
                            @click="saveAsTemplate(scope.row)"
                          >
                            <el-dropdown-item
                              ><i class="el-icon-folder-add"></i> Save As
                              Template</el-dropdown-item
                            >
                          </a>
                          <a
                            @click="shareCompletedDocument(scope.row)"
                            v-if="
                              checkPermissionForDocument(
                                scope.row,
                                'shareUsers'
                              ) && scope.row.document_status == 'COMPLETED'
                            "
                          >
                            <el-dropdown-item
                              ><i class="el-icon-share"></i> Share With
                              Users</el-dropdown-item
                            >
                          </a>
                          <a
                            @click="alertvoided(scope.row._id)"
                            v-if="
                              checkPermissionForDocument(
                                scope.row,
                                'voidDocument'
                              ) &&
                              scope.row.document_status != 'VOIDED' &&
                              scope.row.document_status != 'COMPLETED' &&
                              scope.row.document_status != 'DRAFT' &&
                              scope.row.document_status != 'ARCHIVED'
                            "
                          >
                            <el-dropdown-item
                              ><i class="el-icon-remove-outline"></i> Void
                              Document</el-dropdown-item
                            >
                          </a>
                          <a
                            @click="EditVoidDocument(scope.row, scope.row._id)"
                            v-if="
                              (checkPermissionForDocument(
                                scope.row,
                                'cloneDocument'
                              ) ||
                                scope.row.created_by === authid) &&
                              (scope.row.document_status == 'VOIDED' ||
                                scope.row.document_status == 'COMPLETED')
                            "
                          >
                            <el-dropdown-item
                              ><i class="el-icon-document-copy"></i> Clone
                              Document</el-dropdown-item
                            >
                          </a>
                          <!-- <a
                        @click="
                          openNameModal(
                            scope.row._id,
                            scope.row.title,
                            'duplicate'
                          )
                        "
                        v-if="
                          checkPermissionForDocument(
                            scope.row,
                            'cloneDocument'
                          ) && scope.row.document_status == 'DRAFT'
                        "
                      >
                        <el-dropdown-item
                          ><i class="el-icon-copy-document"></i>
                          Duplicate</el-dropdown-item
                        >
                      </a> -->

                          <a
                            v-if="
                              checkPermissionForDocument(
                                scope.row,
                                'renameDocument'
                              ) && scope.row.document_status != 'VOIDED'
                            "
                            @click="
                              openNameModal(
                                scope.row._id,
                                scope.row.title,
                                'Rename'
                              )
                            "
                          >
                            <el-dropdown-item
                              ><i class="el-icon-edit-outline"></i>Rename
                              Document</el-dropdown-item
                            >
                          </a>

                          <a
                            @click="settings(scope.row._id)"
                            v-if="
                              (scope.row.created_by === authid ||
                                checkPermissionForDocument(
                                  scope.row,
                                  'documentSettings'
                                )) &&
                              scope.row.document_status != 'COMPLETED' &&
                              scope.row.document_status != 'VOIDED' &&
                              scope.row.document_status != 'EXPIRED' &&
                              scope.row.document_status != 'ARCHIVED'
                            "
                          >
                            <el-dropdown-item
                              ><i class="el-icon-setting"></i>Document
                              Settings</el-dropdown-item
                            >
                          </a>
                          <a
                            v-if="
                              (checkPermissionForDocument(
                                scope.row,
                                'downloadDocument'
                              ) &&
                                scope.row.document_status == 'COMPLETED') ||
                              (scope.row.document_status &&
                                scope.row.document_status == 'COMPLETED' &&
                                isInDocumentUsers(scope.row.document_users))
                            "
                            @click="onDownload(scope.row)"
                          >
                            <el-dropdown-item
                              ><i class="el-icon-download"></i>Download
                              Document</el-dropdown-item
                            >
                          </a>
                          <!--<el-dropdown-item
                        v-if="
                          scope.row.document_status != 'VOIDED' &&
                          scope.row.created_by === authid
                        "
                        >Copy Link to share document</el-dropdown-item
                      >-->
                          <a
                            @click="archiveDocument(scope.row._id)"
                            v-if="
                              checkPermissionForDocument(
                                scope.row,
                                'deleteDocument'
                              ) && scope.row.document_status != 'ARCHIVED'
                            "
                          >
                            <el-dropdown-item
                              ><i class="el-icon-delete"></i>Delete
                              Document</el-dropdown-item
                            >
                          </a>
                          <a
                            @click="archiveDocumentundo(scope.row._id)"
                            v-if="
                              scope.row.created_by === authid &&
                              scope.row.document_status === 'ARCHIVED'
                            "
                          >
                            <el-dropdown-item
                              ><i class="el-icon-refresh-left"></i> Undo
                              Delete</el-dropdown-item
                            >
                          </a>
                          <a
                            @click="permanentDocument(scope.row._id)"
                            v-if="
                              checkPermissionForDocument(
                                scope.row,
                                'deleteDocument'
                              ) && scope.row.document_status === 'ARCHIVED'
                            "
                          >
                            <el-dropdown-item
                              ><i class="el-icon-delete-solid"></i> Permanent
                              Delete</el-dropdown-item
                            >
                          </a>
                          <a
                            v-if="
                              (scope.row &&
                                scope.row.document_status === 'SENT' &&
                                isInDocumentUsers(scope.row.document_users) &&
                                scope.row.configurable_document_data &&
                                !scope.row.configurable_document_data
                                  .is_ck_editor_document) ||
                              (checkPermissionForDocument(
                                scope.row,
                                'downloadOriginal'
                              ) &&
                                scope.row.configurable_document_data &&
                                !scope.row.configurable_document_data
                                  .is_ck_editor_document)
                            "
                            @click="originalDownload(scope.row)"
                          >
                            <el-tooltip
                              content="You can download the original document uploaded by 'Document Admin'"
                              placement="left-end"
                            >
                              <el-dropdown-item class="sendEmailLink"
                                ><i class="el-icon-download"></i>Download
                                Original</el-dropdown-item
                              >
                            </el-tooltip>
                          </a>

                          <a
                            v-if="
                              (scope.row.created_by === authid ||
                                checkPermissionForDocument(
                                  scope.row,
                                  'sendToMail'
                                )) &&
                              scope.row.document_status == 'COMPLETED'
                            "
                            @click="sendToMail(scope.row._id, scope.row.name)"
                          >
                            <el-tooltip
                              content="Document owner only can perofrm this action"
                              placement="left-end"
                            >
                              <el-dropdown-item
                                ><i class="el-icon-position pl-2"></i> Send To
                                Mail</el-dropdown-item
                              >
                            </el-tooltip>
                          </a>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </li>
                  </div>
                </div>
              </ul>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        v-if="getIsMobile"
        class="custom-pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="paginationProps"
        :page-size="pageSize"
        layout="prev, pager, next, jumper, sizes, total"
        :total="total"
      ></el-pagination>

      <dialog-component
        :title="modalData.action + ' Document'"
        :visible="documentActionModal"
        @before-close="closeDialog"
        :containerWidth="getIsMobile ? '90%' : '30%'"
        :containerMinHeight="getIsMobile ? '30%' : '25%'"
        class="doc-modal"
        v-loading="modalLoading"
        element-loading-text="Loading..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        v-draggable
      >
        <div>
          <label>Document Title :</label>
          <el-input
            type="text"
            placeholder="Document Name"
            v-model="modalData.name"
          ></el-input>
          <p
            class="error"
            v-if="
              getDuplicateDocumentErrors &&
              getDuplicateDocumentErrors.critical_error
            "
          >
            {{ getDuplicateDocumentErrors.critical_error }}
          </p>
        </div>
        <span slot="footer" class="dialog-footer mt-0">
          <el-button @click="documentActionModal = false">Cancel</el-button>
          <el-button
            type="primary"
            @click="handleDocAction"
            :disabled="modalData.name.length == 0"
            >Confirm</el-button
          >
        </span>
      </dialog-component>
    </div>
    <dialog-component
      class="outersize"
      title="Welcome"
      :visible="outerVisible"
      @before-close="closeWelcomeDialog"
    >
      <div class="dilog-header">
        <p class="headp">Welcome to eSigns</p>
        <p class="head1p">
          eSigns simply allows you to send and sign the document practically
          anywhere, at anytime.
        </p>
      </div>
      <!--here what we make it as responsive-->
      <div class="main-image">
        <el-row>
          <el-col :lg="6" :md="12" :sm="12" :xs="24">
            <div class="box">
              <div class="center">
                <p class="put_numbers">1</p>
              </div>
              <!-- <img class="numbers" src="@/assets/Ellipse 67.svg" /> -->
              <img class="arrows" src="@/assets/Icon-Arrow.svg" />
              <img src="@/assets/upload-file.svg" />
              <p style="color: black; font-size: 12px">Upload your document</p>
              <p>Upload a drag file from your <br />computer</p>
            </div>
          </el-col>
          <el-col :lg="6" :md="12" :sm="12" :xs="24">
            <div class="box">
              <div class="center">
                <p class="put_numbers">2</p>
              </div>
              <!-- <img class="numbers" src="@/assets/Ellipse 67.svg" /> -->
              <img class="arrows" src="@/assets/Icon-Arrow.svg" />
              <img src="@/assets/Icon-Add Text Fields on document.svg" />
              <p style="color: black; font-size: 12px">Add text fields</p>
              <p>
                Include fields on your documents to fill information and
                signature
              </p>
            </div>
          </el-col>
          <el-col :lg="6" :md="12" :sm="12" :xs="24">
            <div class="box">
              <div class="center">
                <p class="put_numbers">3</p>
              </div>
              <!-- <img class="numbers" src="@/assets/Ellipse 67.svg" /> -->
              <img class="arrows" src="@/assets/Icon-Arrow.svg" />
              <img src="@/assets/send out.svg" />
              <p style="color: black; font-size: 12px">Send Out</p>
              <p>
                Add recipients and send the documents to one or multiple
                recipients
              </p>
            </div>
          </el-col>
          <el-col :lg="6" :md="12" :sm="12" :xs="24">
            <div class="box">
              <div class="center">
                <p class="put_numbers">4</p>
              </div>
              <!-- <img class="numbers" src="@/assets/Ellipse 67.svg" /> -->
              <img src="@/assets/Icon-Email Notification.svg" />
              <p style="color: black; font-size: 12px">Email Notification</p>
              <p>
                Once the document is completed you'll get an email notification
              </p>
            </div>
          </el-col>
        </el-row>
      </div>
      <span slot="footer">
        <div class="footer-dialog">
          <el-button id="bsty" @click="outerVisible = false"
            >LET'S GO</el-button
          >
        </div>
      </span>
    </dialog-component>
    <dialog-component
      :title="'Share Document'"
      :visible="templateShareModal"
      :containerWidth="getIsMobile ? '100%' : '50vw'"
      :containerMaxHeight="'500px'"
      class="doc-modal"
      v-loading="modalLoading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      @before-close="resetShareDocumentData"
      v-draggable
    >
      <div>
        <el-input
          type="text"
          v-model="searchedContact"
          :placeholder="
            activeName == 'contacts'
              ? 'Search by Email'
              : 'Search by Contact Type'
          "
          class="mb-1"
          @input="searchContact"
          clearable
        ></el-input>
        <el-tabs v-model="activeName">
          <el-tab-pane label="Contacts" name="contacts">
            <p>You can share this document to any of your contact.</p>
            <div
              v-if="
                contactUsers && contactUsers.length && !searchedContact.length
              "
            >
              <el-scrollbar wrap-style="max-height: 150px;">
                <div style="border-bottom: 1px solid #eaeaea; height: 45px">
                  <el-checkbox
                    :label="selectAllContacts ? 'Deselect All' : 'Select All'"
                    class="mt-1"
                    @change="selectAllEmails"
                    v-model="selectAllContacts"
                  ></el-checkbox>
                </div>
                <el-checkbox-group v-model="selectedUsers">
                  <div
                    v-for="(el, i) in contactUsers"
                    :key="i"
                    style="border-bottom: 1px solid #eaeaea; height: 45px"
                  >
                    <el-checkbox :label="el.email" class="mt-1"></el-checkbox>
                  </div>
                </el-checkbox-group>
              </el-scrollbar>
            </div>
            <div v-else-if="searchedContact.length">
              <el-scrollbar wrap-style="max-height: 150px;">
                <div style="border-bottom: 1px solid #eaeaea; height: 45px">
                  <el-checkbox
                    :label="selectAllContacts ? 'Deselect All' : 'Select All'"
                    class="mt-1"
                    @change="selectAllEmails"
                    v-model="selectAllContacts"
                  ></el-checkbox>
                </div>
                <el-checkbox-group
                  v-model="selectedUsers"
                  v-if="searchedContacts.length"
                >
                  <div
                    v-for="el in searchedContacts"
                    :key="el.email"
                    style="border-bottom: 1px solid #eaeaea; height: 45px"
                  >
                    <el-checkbox :label="el.email" class="mt-1"></el-checkbox>
                  </div>
                </el-checkbox-group>
                <p v-else>No result</p>
              </el-scrollbar>
            </div>
            <div v-else style="text-align: center; height: 100px">
              No contacts<br />
              <el-button
                class="mt-1"
                type="primary"
                size="mini"
                @click="goToAddContacts"
                >Add Contacts</el-button
              >
            </div>
          </el-tab-pane>
          <el-tab-pane label="Contact types" name="contact_types">
            <div
              v-if="
                contactTypes && contactTypes.length && !searchedContact.length
              "
              style="overflow: scroll"
            >
              <div style="border-bottom: 1px solid #eaeaea; height: 45px">
                <el-checkbox
                  :label="selectAllContactTypes ? 'Deselect All' : 'Select All'"
                  class="mt-1"
                  @change="selectAllContactsTypes"
                  v-model="selectAllContactTypes"
                ></el-checkbox>
              </div>
              <el-checkbox-group
                v-model="selectedContactTypes"
                @change="selectContactTypeContacts"
              >
                <div
                  v-for="(el, i) in contactTypes"
                  :key="i"
                  style="border-bottom: 1px solid #eaeaea; height: 45px"
                >
                  <el-checkbox :label="el.name" class="mt-1"></el-checkbox>
                </div>
              </el-checkbox-group>
            </div>
            <div v-if="searchedContact.length">
              <div style="border-bottom: 1px solid #eaeaea; height: 45px">
                <el-checkbox
                  :label="selectAllContactTypes ? 'Deselect All' : 'Select All'"
                  class="mt-1"
                  @change="selectAllContactsTypes"
                  v-model="selectAllContactTypes"
                ></el-checkbox>
              </div>
              <el-scrollbar wrap-style="max-height: 150px;">
                <el-checkbox-group
                  v-model="selectedContactTypes"
                  v-if="searchedContactTypes.length"
                >
                  <div
                    v-for="(el, i) in searchedContactTypes"
                    :key="i"
                    style="border-bottom: 1px solid #eaeaea; height: 45px"
                  >
                    <el-checkbox :label="el.name" class="mt-1"></el-checkbox>
                  </div>
                </el-checkbox-group>
                <p v-else>No result</p>
              </el-scrollbar>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <span slot="footer">
        <el-button @click="resetShareDocumentData">Cancel</el-button>
        <el-button type="primary" @click="shareTemplateToContacts"
          >Save & Update</el-button
        >
      </span>
    </dialog-component>
    <dialog-component
      :title="'Send To Mail'"
      :visible="isSendToMail"
      :width="getIsMobile ? '100%' : '38%'"
      @before-close="closeSendToMail"
      :containerWidth="getIsMobile ? '100%' : '50vw'"
      :containerMaxHeight="'60%'"
      style="margin-top: 5%"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      v-draggable
    >
      <el-form ref="form" :model="form" clas="py-3 px-4">
        <el-row>
          <el-col :span="24" :lg="{ span: 24 }" :xs="{ span: 24 }">
            <el-form-item class="mb-05" label="Email" prop="email">
              <!-- v-model="user.email" -->
              <el-select
                autocomplete="on"
                class="w-100"
                placeholder="Search with Email"
                suffix-icon="el-icon-search"
                v-model="selectedMail"
                @change="validateMails()"
                multiple
                clearable
                filterable
                :allow-create="true"
                default-first-option
                ref="searchSelect"
              >
                <el-option
                  v-for="(contact, index) in allContacts"
                  :key="index"
                  :value="contact.email"
                  :label="contact.email"
                >
                  {{ contact.email }}
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" :lg="{ span: 24 }" :xs="{ span: 24 }">
            <el-form-item label="Subject" class="mb-05">
              <el-input v-model="form.emailSubject"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" :lg="{ span: 24 }" :xs="{ span: 24 }">
            <el-form-item label="Body" class="mb-05">
              <el-input
                type="textarea"
                :rows="4"
                v-model="form.emailBody"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span
        slot="footer"
        :style="getIsMobile ? 'margin-left: 25%' : 'margin-left: 55%'"
      >
        <el-button @click="cancelSendToMail">Cancel</el-button>
        <el-button
          type="primary"
          :disabled="!selectedMail.length"
          @click="sendCompletedDocument"
          >Send</el-button
        >
      </span>
    </dialog-component>
    <dialog-component
      :visible="ResendDocumentToRecipient"
      title="Document Users"
      :containerWidth="'60vw'"
      @before-close="closeResendToRecipient"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div>
        <el-table :data="resendDocumentUsers" style="width: 100%" border>
          <el-table-column
            prop="first_name"
            label="First Name"
            min-width="100"
          ></el-table-column>
          <el-table-column
            prop="last_name"
            label="Last Name"
            min-width="100"
          ></el-table-column>
          <el-table-column
            prop="email"
            label="Email"
            min-width="180"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="Role"
            width="130"
          ></el-table-column>
          <!-- <el-table-column
        prop="address"
        label="Address"
        min-width="180"
      ></el-table-column> -->
          <!-- <el-table-column
        prop="company_name"
        label="Company"
        width="100"
      ></el-table-column> -->
          <el-table-column prop="company_name" label="Other" width="125">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="resendDocLink(scope.row)"
                v-if="
                  scope.row.sent_status &&
                  !scope.row.document_filling_status &&
                  scope.row.type != 'SENDER' &&
                  scope.row.user_type != 'CC'
                  //isUserIsDocumentOwner &&
                  //isAccessDocument
                "
              >
                Resend</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </dialog-component>
    <el-dialog
      :visible.sync="sharepointDataDialog"
      title="Sharepoint Folders"
      draggable="true"
      width="55%"
      :close-on-click-modal="false"
      v-loading="loading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-row style="margin-bottom: 10px">
        <el-col :span="18">
          <div class="breadcrumbs">
            <span class="breadcrumb-item" @click="handleBreadcrumbClick('')"
              >Home</span
            >
            <span v-for="(folder, index) in pathArray" :key="index">
              <span
                class="breadcrumb-item"
                @click="handleBreadcrumbClick(folder)"
                >{{ folder }}</span
              >
              <span class="breadcrumb-separator">/</span>
            </span>
          </div>
        </el-col>
        <el-col :span="6">
          <el-input
            size="small"
            v-model="searchQuery"
            placeholder="Search..."
            clearable
            @clear="searchQuery = ''"
          ></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-table
          :data="pagedSharepointData"
          :default-sort="{ prop: 'type', order: 'ascending' }"
          max-height="320"
          :header-cell-style="{ background: '#F1EFF0' }"
          border
        >
          <el-table-column label="Name" prop="file" sortable>
            <template slot-scope="scope">
              <div
                v-if="scope.row.type == 'folder'"
                class="folder-column"
                @click="handlePath(scope.row.file)"
              >
                <i class="el-icon-folder"></i>
                {{ scope.row.file }}
              </div>
              <!-- <div v-else-if="scope.row.type=='file'">
                {{ scope.row.file }}
              </div> -->
            </template>
          </el-table-column>
          <el-table-column
            prop="lastModified"
            label="LastModified"
            width="230"
            sortable
          >
            <template slot-scope="scope">
              {{ scope.row.lastModified }}
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-container">
          <el-pagination
            @size-change="handleSharepointSizeChange"
            @current-change="handleCurrentPageChange"
            :page-sizes="[5, 10, 20, 30]"
            :page-size="dialogTablePageSize"
            :current-page="dialogTableCurrentPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="filteredSharepointData.length"
          >
          </el-pagination>
          <el-col align="right" :span="4" class="button-container">
            <el-tooltip
              class="item"
              effect="dark"
              content="Download to this folder"
              placement="bottom"
            >
              <div>
                <el-button
                  size="mini"
                  type="primary"
                  @click="
                    uploadFileToSharePoint(sharepointAccessToken, fileScope)
                  "
                  >Download Here</el-button
                >
              </div>
            </el-tooltip>
          </el-col>
        </div>
      </el-row>
    </el-dialog>
    <dialog-component
      :visible="redirectDialog"
      :title="'Register Sharepoint'"
      :draggable="true"
      :containerWidth="getIsMobile ? '100%' : '30%'"
      :close-on-click-modal="false"
    >
      <el-row style="margin-bottom: 15px">
        <el-col>
          <div>
            You haven't registered your sharepoint application yet.Please
            register to use services.
          </div>
        </el-col>
      </el-row>
      <span slot="footer">
        <el-tooltip
          class="item"
          effect="dark"
          content="Register App"
          placement="bottom"
        >
          <router-link to="/profilesettings/apps-integrations">
            <el-button
              size="small"
              class="mb-1 ml-2 mr-1"
              style="width: 100px"
              type="primary"
            >
              Register App
            </el-button>
          </router-link>
        </el-tooltip>
      </span>
    </dialog-component>
    <dialog-component
      :visible="downloadSingleDocument"
      :title="'Select Document'"
      draggable="true"
      :containerWidth="getIsMobile ? '100%' : '40%'"
      :close-on-click-modal="false"
      :isShowFooter="false"
      @before-close="downloadSingleDocument = false"
    >
      <el-table
        style="padding: 10px"
        :data="allCompletedDocuments"
        v-loading="SingleDocumentDownloadLoading"
        :header-cell-style="{ background: 'var(--lighter-primary-color)' }"
      >
        <el-table-column prop="name" label="Name" width="400">
        </el-table-column>
        <el-table-column label="Download" width="120">
          <template slot-scope="scope">
            <i
              class="el-icon-download"
              @click="downloadCompletedDocumentForUser(scope.row)"
              style="cursor: pointer"
            ></i>
          </template>
        </el-table-column>
      </el-table>
    </dialog-component>
    <ConfigureDocumentUsers
      v-if="addDocumentRecipient"
      :documentUsers="documentUsers"
      :enforceSignatureOrder="enforceSignatureOrder"
      :allElements="elements"
      v-on:close="closeDocumentUserSettings"
    ></ConfigureDocumentUsers>
    <ConfigureSettings
      v-if="docSettingsVisible"
      :configurableDocumentId="configurableDocumentId"
      :docSettingsVisible="docSettingsVisible"
      @close="updateSettings"
    ></ConfigureSettings>
  </section>
</template>
<script>
import { bus } from "../../main";
import { mapGetters } from "vuex";
import store from "@/store";
import moment from "moment";
import axios from "@/config/axios";
import appConfig from "@/config/app";
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications.js";
const ConfigureSettings = () =>
  import(
    "@/components/companyDocuments/configureDocuments/ConfigureSettingsNewTemp"
  );
const ConfigureDocumentUsers = () =>
  import(
    "@/components/companyDocuments/configureDocuments/ConfigureDocumentUsers"
  );
import PermissionsHelper from "@/mixins/permissionsHelper";
import ComanyDocumentsPresignedURLsHelper from "@/mixins/ComanyDocumentsPresignedURLsHelper";

export default {
  name: "companyDocuments-DocumentsList",
  components: {
    ConfigureDocumentUsers,
    ConfigureSettings,
  },
  props: [
    "page_size",
    "selectedDocStatus",
    "search_string",
    "search_by",
    "selected_company_user",
  ],
  mixins: [PermissionsHelper, ComanyDocumentsPresignedURLsHelper],
  data() {
    return {
      permissionSetForUsers: [
        "cloneDocument",
        "voidDocument",
        "shareUsers",
        "saveTemplate",
        "resendDocument",
        "correctDocument",
        "companyUser",
        "editDraft",
        "deleteDocument",
        "downloadOriginal",
        "downloadDocument",
        "viewDocument",
        "signDocuments",
        "renameDocument",
        "documentSettings",
        "sendToMail",
      ],
      isValid: true,
      sendingMail: false,
      allContacts: [],
      selectedMail: [],
      isSendToMail: false,
      ResendDocumentToRecipient: false,
      is_documentDelete: false,
      selected_document: [],
      resendDocumentUsers: [],
      resendEmployeeDocumentId: null,
      addDocumentRecipient: false,
      docSettingsVisible: false,
      loading: false,
      outerVisible: false,
      editDailogVisible: false,
      selectedCompanyDocument: "",
      total: 0,
      currentPage: 1,
      pageSize: 0,
      page: 1,
      order_type: null,
      order_by: null,
      configureLoading: false,
      configureLoadingMessage: "Document Loading...",
      openSendDocumentsModal: false,
      documentUsers: [],
      showSendDocumentoUserSettings: false,
      enforceSignatureOrder: null,
      documents: [],
      activeName: "contacts",
      modalData: {
        document_id: "",
        name: "",
        action: "",
      },
      documentActionModal: false,
      modalLoading: false,
      authid: "",
      tagType: ["success", "info", "", "warning", "danger"],
      limit: 2,
      nodoc: [],
      nonsig: [],
      selectedUsers: [],
      contactUsers: [],
      templateShareModal: false,
      selectedDocumentUsers: [],
      selectedDocumentId: null,
      selectedContactTypes: [],
      contactTypes: [],
      searchedContact: "",
      searchedContacts: [],
      searchedContactTypes: [],
      selectAllContacts: false,
      selectAllContactTypes: false,
      sharepointAccessToken: "",
      sharepointData: [],
      sharepointDataDialog: false,
      dialogTableCurrentPage: 1,
      dialogTablePageSize: 5,
      searchQuery: "",
      path: "",
      fileScope: "",
      redirectDialog: false,
      appData: {},
      isDocumentDelete: "",
      subjectName: "",
      form: {
        emailSubject: this.subjectName,
        emailBody:
          "Please find signed document attached to this mail. Anticipating your action at the earliest.  ",
        documentId: "",
      },
      oneDriveData: {
        accessToken: "",
        expiresIn: 0,
      },
      downloadSingleDocument: false,
      testtt: "",
      allCompletedDocuments: [],
      SingleDocumentDownloadLoading: false,
    };
  },
  created() {
    bus.$on("documentLoading", (data) => {
      this.isDocumentDelete = data;
      this.loadingDocument();
    });
  },
  computed: {
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveWorkspace",
      "getUserType",
    ]),
    ...mapGetters("documents", [
      "getSingleDocumentData",
      "getSingleDocumentErrors",
      "getAllDocuments",
      "getRenameDocumentStatus",
      "getDuplicateDocumentStatus",
      "getArchiveDocumentStatus",
      "getDuplicateDocumentErrors",
      "getDocumentVoidStatus",
      "getResendDocumentStatus",
      "getDocumentSaveAsTemplate",
      "getDocumentCorrectStatus",
      "getDocumentCorrectErrors",
      "getCloneVoidDocStatus",
      "getCloneVoidDocData",
      "getUpdateDocumentUsersStatus",
      "getDocumentSaveAsTemplateData",
      "getSendCompletedDocumentStatus",
    ]),
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("configurableDocuments", ["getConfigurableDocumentDataById"]),
    ...mapGetters("contacts", ["getContactUser", "getAllContacts"]),
    ...mapGetters("contactTypes", ["getAllContactTypes"]),
    paginationProps() {
      return [5, 10, 20, 50];
    },
    paginationPropsForTable() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50],
      };
    },
    basePdfdownloadUrl() {
      // return appConfig.JAVA_BASE_URL
      return appConfig.S3_BUCKET_URL;
    },
    isUserIsDocumentOwner() {
      if (
        this.getSingleDocumentData &&
        this.getSingleDocumentData.data &&
        this.getSingleDocumentData.data.user_id &&
        this.getSingleDocumentData.data.user_id.email &&
        this.currentAuthUserEmail ==
          this.getSingleDocumentData.data.user_id.email.toLowerCase()
      ) {
        return true;
      } else {
        return false;
      }
    },

    isAccessDocument() {
      if (
        this.getSingleDocumentData &&
        this.getSingleDocumentData.data &&
        this.getSingleDocumentData.data.document_status &&
        this.getSingleDocumentData.data.document_status != "DECLINED" &&
        this.getSingleDocumentData.data.document_status != "ARCHIVED" &&
        this.getSingleDocumentData.data.document_status != " VOIDED" &&
        this.getSingleDocumentData.data.document_status != "EXPIRED" &&
        this.getSingleDocumentData.data.document_status != "COMPLETED"
      ) {
        return true;
      } else {
        return false;
      }
    },
    isImage() {
      return (type) => (type && type.indexOf("image") > -1 ? true : false);
    },
    isPdf() {
      return (type) => (type && type.indexOf("pdf") > -1 ? true : false);
    },
    pagedSharepointData() {
      const startIndex =
        (this.dialogTableCurrentPage - 1) * this.dialogTablePageSize;
      const endIndex = startIndex + this.dialogTablePageSize;
      return this.filteredSharepointData.slice(startIndex, endIndex);
    },
    pathArray() {
      return this.path.split("/");
    },
    filteredSharepointData() {
      if (this.searchQuery) {
        const searchTerm = this.searchQuery.toLowerCase();
        return this.sharepointData.filter((item) =>
          item.file.toLowerCase().includes(searchTerm)
        );
      } else {
        return this.sharepointData;
      }
    },
  },
  async mounted() {
    this.pageSize = this.page_size ? parseInt(this.page_size) : 10;
    // this.outerVisible = true;
    await this.fetchDocuments();
    await this.fetchContactTypes();
    const storedToken = localStorage.getItem("oneDriveToken");
    if (storedToken) {
      const token = JSON.parse(storedToken);
      this.oneDriveData.accessToken = token.accessToken;
      this.oneDriveData.expiresIn = token.expiresIn;
    }
  },
  methods: {
    handleCloseModal() {
      this.downloadSingleDocument = false;
    },
    showActions(row) {
      let check = true;
      const isPermissionIncluded = this.permissionSetForUsers.some(
        (permission) =>
          this.getUserType &&
          this.getUserType.permissionSet &&
          this.getUserType.permissionSet.includes(permission)
      );
      if (
        (this.getUserType &&
          this.getUserType.name &&
          this.getUserType.name === "OWNER") ||
        isPermissionIncluded
      ) {
        return check;
      }
      row.document_users.forEach((user) => {
        if (user.type == "RECEIVER" && row.created_by != this.authid) {
          check = false;
        }
      });
      return check;
    },
    closeResendToRecipient() {
      this.ResendDocumentToRecipient = false;
    },
    closeSendToMail() {
      this.isSendToMail = false;
    },
    closeDialog() {
      this.documentActionModal = false;
    },
    closeWelcomeDialog() {
      this.outerVisible = false;
    },
    updateSettings() {
      this.docSettingsVisible = false;
      this.fetchDocuments();
    },
    validateMails() {
      let re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let filteredMail = [];
      filteredMail = this.selectedMail.filter((e) => {
        if (re.test(e)) {
          return e;
        } else {
          this.isValid = false;
        }
      });
      if (!this.isValid) {
        //  this.$message({
        //   message: 'Please Enter a valid email',
        //   type: 'warning'
        // });
        this.selectedMail = filteredMail;
      }
    },
    async sendToMail(id, name) {
      this.form.documentId = id;
      this.subjectName = name;
      this.form.emailSubject = "Signed  " + this.subjectName;
      let params = { get_all: true, type: "CONTACT" };
      await this.$store.dispatch("contacts/fetchAllContacts", params);
      this.allContacts = this.getAllContacts.data;
      this.isSendToMail = true;
      this.configureLoading = false;
    },
    cancelSendToMail() {
      this.isSendToMail = false;
      this.form.documentId = "";
      this.selectedMail = [];
      this.form.emailSubject = "Signed  " + this.subjectName;
      this.form.emailBody =
        "Please find attached signed document attached to this mail. Anticipating your action at the earliest.";
    },
    async sendCompletedDocument() {
      let params = {
        docId: this.form.documentId,
        email: this.selectedMail,
        emailSubject: this.form.emailSubject,
        emailBody: this.form.emailBody,
      };
      this.sendingMail = true;
      this.isSendToMail = false;
      this.configureLoading = true;
      this.configureLoadingMessage = "Document Sending....";
      await this.$store.dispatch("documents/sendCompletedDocument", params);
      if (this.getSendCompletedDocumentStatus) {
        this.$notify.success({
          title: "Success",
          message: "Document sent successfully",
        });
        this.configureLoading = false;
        this.configureLoadingMessage = "";

        this.form.documentId = "";
        this.selectedMail = [];
        this.form.emailSubject = "Signed  " + this.subjectName;
        this.form.emailBody =
          "Please find signed document attached to this mail. Anticipating your action at the earliest.";
      } else {
        errorNotification("Error in sending document");
      }
    },
    async loadingDocument() {
      this.configureLoadingMessage = "Document Loading....";
      this.configureLoading = true;
      await this.fetchDocuments();
    },
    isInDocumentUsers(documentUsers) {
      let hasUser = documentUsers
        ? documentUsers.findIndex(
            (cu) =>
              cu &&
              cu.email &&
              this.getAuthenticatedUser &&
              this.getAuthenticatedUser.email &&
              cu.email == this.getAuthenticatedUser.email
          )
        : -1;
      if (hasUser != -1) {
        return true;
      } else {
        return false;
      }
    },
    handleSelectionChange(val) {
      this.selected_document = [];
      this.selected_document = val;
    },
    selectAllEmails() {
      if (this.selectAllContacts) {
        this.contactUsers.forEach((el) => {
          this.selectedUsers.push(el.email);
        });
      } else {
        this.selectAllContactTypes = false;
        this.selectedUsers = [];
        this.selectedContactTypes = [];
      }
    },
    selectAllContactsTypes() {
      if (this.selectAllContactTypes) {
        this.contactTypes.forEach((el) => {
          this.selectedContactTypes.push(el.name);
        });
        this.contactUsers.forEach((el) => {
          this.selectedUsers.push(el.email);
        });
        this.selectAllContacts = true;
      } else {
        this.selectAllContacts = false;
        this.selectedContactTypes = [];
        this.selectedUsers = [];
      }
    },
    searchContact() {
      if (this.activeName == "contacts") {
        if (this.searchedContact) {
          this.searchedContacts = this.contactUsers.filter((el) =>
            el.email.includes(`${this.searchedContact}`)
          );
        }
      } else {
        if (this.searchedContact) {
          this.searchedContactTypes = this.contactTypes.filter((el) =>
            el.name.includes(`${this.searchedContact}`)
          );
        }
      }
    },
    async fetchContactTypes() {
      let params = { get_all: true };
      await this.$store.dispatch("contactTypes/fetchContactTypes", params);
      if (this.getAllContactTypes) {
        this.contactTypes = this.getAllContactTypes.data;
      }
    },
    selectContactTypeContacts() {
      // this.selectedUsers = [...this.selectedUsers, ...this.currentExistedUsers];
      if (this.selectedContactTypes && this.selectedContactTypes.length) {
        this.contactUsers.forEach((element) => {
          let contactTypes = [];
          if (element?.contact_types) {
            element?.contact_types.forEach((e) => {
              if (e?.contact_type?.name) {
                contactTypes.push(e.contact_type.name);
              }
            });
          } else if (element?.contact_type?.name) {
            contactTypes.push(element.contact_type.name);
          }
          let foundIndex = (contactTypes || []).findIndex((el) => {
            return this.selectedContactTypes.indexOf(el) != -1;
          });
          if (foundIndex != -1) {
            if (this.selectedUsers.indexOf(element.email) == -1) {
              this.selectedUsers.push(element.email);
            }
          } else {
            let index = this.selectedUsers.findIndex((e) => e == element.email);
            if (index != -1) {
              this.selectedUsers.splice(index, 1);
            }
          }
        });
      } else {
        this.selectedUsers = [];
      }
    },
    closeDocumentUserSettings({
      users,
      enforceSignatureOrder,
      signaturesConfig,
      submit,
    }) {
      if (users && users.length) {
        this.documentUsers = users;
      }
      if (signaturesConfig && signaturesConfig.length) {
        this.signaturesConfig = signaturesConfig;
      }
      this.submitEvent = false;
      this.showDocumentUserSettings = false;
      this.addDocumentRecipient = false;
      this.enforceSignatureOrder = enforceSignatureOrder;
      if (submit) {
        // this.saveUpdateData();
      }
    },
    async ResendDocument(data) {
      console.log("data", data);
      console.log(
        " this.ResendDocumentToRecipient",
        this.ResendDocumentToRecipient
      );
      this.resendDocumentUsers = data.document_users;
      this.ResendDocumentToRecipient = true;
      this.resendEmployeeDocumentId = data._id;
    },
    async resendDocLink(contact) {
      console.log("-------------------", contact);
      try {
        let params = {
          document_id: this.resendEmployeeDocumentId,
          contact_id:
            contact && contact.contact._id
              ? contact.contact._id
              : contact.contact_id._id,
          is_new_user: false,
        };
        this.esignLoading = true;
        await this.$store.dispatch(
          "documents/resendDocumentLinkToContact",
          params
        );
        this.esignLoading = false;
        if (this.getResendDocumentStatus) {
          this.$notify.success({
            title: "Success",
            message: "Document link sent successfully",
          });
        }
      } catch (err) {
        this.esignLoading = false;
        console.log(err);
      }
    },
    async shareTemplateToContacts() {
      // if (!this.selectedUsers.length) {
      //   this.$message({
      //     message: "Please select atleast one recipient email!",
      //     type: "warning",
      //   });
      // } else {
      if (this.selectedUsers && this.selectedUsers.length) {
        console.log(this.selectedUsers);
        console.log(this.selectedUsers.length);
        let newUsers = [];
        // let existed_users = this.selectedDocumentUsers.filter(user => user.name.split("-")[0] == 'NEW_RECEIVER').flatMap(user => user.email)
        this.selectedDocumentUsers = this.selectedDocumentUsers.filter(
          (user) => user.name.split("-")[0] != "NEW_RECEIVER"
        );
        this.contactUsers.forEach((user) => {
          if (
            user &&
            user.email &&
            this.selectedUsers.indexOf(user.email) != -1
          ) {
            newUsers.push({
              default: false,
              name:
                "NEW_RECEIVER-" +
                parseInt(
                  this.selectedDocumentUsers.length +
                    this.selectedUsers.indexOf(user.email) +
                    1
                ),
              value:
                "NEW_RECEIVER_" +
                parseInt(
                  this.selectedDocumentUsers.length +
                    this.selectedUsers.indexOf(user.email) +
                    1
                ),
              type: "RECEIVER",
              e_signature_fields: [],
              e_signature_date_fields: [],
              e_signature_order:
                this.selectedDocumentUsers.length +
                this.selectedUsers.indexOf(user.email) +
                1,
              e_signature_required: false,
              has_approval_access: false,
              color: "#0652DD",
              email: user.email || "",
              first_name: user.first_name || "",
              last_name: user.last_name || "",
              phone: user.phone || "",
              address: user.address || "",
              company_name: user.company_name || "",
              user_type: "CC",
              sent_status: true,
              is_cc: true,
              fields_required: false,
              contact_type: user.contact_type,
              contact: user,
              contact_id: user._id,
              terms_and_conditions: true,
            });
          }
        });
        this.selectedDocumentUsers = [
          ...this.selectedDocumentUsers,
          ...newUsers,
        ];
        let params = {
          document_users: this.selectedDocumentUsers,
          document_id: this.selectedDocumentId,
        };
        await this.$store.dispatch("documents/shareDocumentUsers", params);
        if (this.getUpdateDocumentUsersStatus) {
          this.$notify.success({
            title: "Success",
            message: "Document Shared Successfully.",
          });
          this.$emit("reloadDashboardStats", true);
          this.resetShareDocumentData();
          this.fetchDocuments();
        }
        // this.$notify.success({
        //   title: "Success",
        //   message: "Document Shared Successfully",
        // });
      } else {
        this.selectedDocumentUsers = this.selectedDocumentUsers.filter(
          (user) => user.name.split("-")[0] != "NEW_RECEIVER"
        );
        let params = {
          document_users: this.selectedDocumentUsers,
          document_id: this.selectedDocumentId,
        };
        await this.$store.dispatch("documents/shareDocumentUsers", params);
        if (this.getUpdateDocumentUsersStatus) {
          this.$notify.success({
            title: "Success",
            message: "Shared document users updated successfully.",
          });
          this.$emit("reloadDashboardStats", true);
          this.resetShareDocumentData();
          this.fetchDocuments();
        }
      }
      //  }
    },
    resetShareDocumentData() {
      this.selectedDocumentUsers = [];
      this.selectedDocumentId = null;
      this.selectedUsers = [];
      this.contactUsers = [];
      this.templateShareModal = false;
    },
    goToAddContacts() {
      this.$router.push({ path: "/contacts/add" });
    },
    async shareCompletedDocument(data) {
      if (data && data.document_users) {
        this.configureLoading = true;
        this.selectedDocumentUsers = data.document_users;
        this.selectedDocumentId = data._id;
        let documentUsers = data.document_users
          .filter(
            (user) =>
              user.type != "SENDER" && user.name.split("-")[0] != "NEW_RECEIVER"
          )
          .flatMap((user) => user.email);
        await this.$store.dispatch("contacts/fetchContactUser");
        if (this.getContactUser && this.getContactUser.data) {
          this.getContactUser.data.forEach((user) => {
            if (user && user.email && documentUsers.indexOf(user.email) == -1) {
              this.contactUsers.push(user);
            }
          });
          if (this.contactUsers) {
            this.selectedUsers = this.selectedDocumentUsers
              .filter(
                (user) =>
                  user.type != "SENDER" &&
                  user.name.split("-")[0] == "NEW_RECEIVER"
              )
              .flatMap((user) => user.email);
            this.configureLoading = false;
            this.templateShareModal = true;
          }
        }
      }
    },
    getSenderEmail(data) {
      let email = "";
      if (data && data.document_users) {
        let sender = data.document_users.find((u) => u.type == "SENDER");
        if (sender && sender.email) {
          email = sender.email;
        }
      }
      return email;
    },
    getSenderDetails(data) {
      let names = "";
      if (data && data.document_users) {
        let sender = data.document_users.find((u) => u.type == "SENDER");
        if (sender && sender.first_name && sender.last_name) {
          names = sender.first_name + " " + sender.last_name;
        }
      }
      return names;
    },
    async downloadAlldocuments(data) {
      this.onDownload(data);
      let allAttachments = this.attachmentsFiles(data);
      if (allAttachments && allAttachments.length) {
        let paths = allAttachments.map((el) => el.attachment_url);
        let titles = allAttachments.map((el) => el.title);
        /** MIXIN */
        await this.downloadFilesFromS3Paths(paths, titles);
      }
    },

    async gotoMainDocument(data) {
      if (data && data.document_completed_url) {
        let file_name = data.title;
        /** MIXIN */
        await this.downloadFilesFromS3Paths(
          [data.document_completed_url],
          [file_name]
        );
      }
    },
    async gotoAttachment(file) {
      if (file && file.attachment_url) {
        let file_name = file.title;
        /** MIXIN */
        await this.downloadFilesFromS3Paths([file.attachment_url], [file_name]);
      }
    },
    attachmentsFiles(data) {
      if (
        data &&
        data.configurable_document_data &&
        data.document_data &&
        data.configurable_document_data.pages &&
        data.configurable_document_data.pages[0] &&
        data.configurable_document_data.pages[0].fields
      ) {
        let result = [];
        let attachments = [];
        let currentUser = data.document_users.find(
          (user) =>
            user &&
            user.email &&
            this.getAuthenticatedUser &&
            this.getAuthenticatedUser.email &&
            user.email == this.getAuthenticatedUser.email
        );
        if (currentUser && currentUser.type == "SENDER") {
          attachments = data.configurable_document_data.pages[0].fields
            .filter((field) => field.type == "ATTACHMENT")
            .flatMap((el) => el.key);
        } else {
          attachments = data.configurable_document_data.pages[0].fields
            .filter(
              (field) =>
                field.type == "ATTACHMENT" &&
                currentUser &&
                currentUser.value &&
                (currentUser.value == field.filled_by ||
                  field.filled_by == "SENDER")
            )
            .flatMap((el) => el.key);
        }
        attachments.forEach((el) => {
          if (data.document_data[el] && data.document_data[el].length) {
            result = [...result, ...data.document_data[el]];
          }
        });
        return result;
      }
    },
    isPrintAndSignDoc(data) {
      console.log(data);
    },
    isDocumentHasAttachment(data) {
      if (
        data &&
        data.configurable_document_data &&
        data.configurable_document_data.pages &&
        data.configurable_document_data.pages[0] &&
        data.configurable_document_data.pages[0].fields &&
        data.document_users &&
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.email
      ) {
        let currentUser = data.document_users.find(
          (user) =>
            user && user.email && user.email == this.getAuthenticatedUser.email
        );
        if (currentUser && currentUser.type && currentUser.type == "SENDER") {
          let attachments = data.configurable_document_data.pages[0].fields
            .filter((field) => field.type == "ATTACHMENT")
            .flatMap((el) => el.key);
          if (attachments && attachments.length) {
            return true;
          }
        } else {
          let attachments = data.configurable_document_data.pages[0].fields
            .filter(
              (field) =>
                field.type == "ATTACHMENT" &&
                currentUser &&
                currentUser.value &&
                (field.filled_by == currentUser.value ||
                  field.filled_by == "SENDER")
            )
            .flatMap((el) => el.key);
          if (attachments && attachments.length) {
            return true;
          }
          return false;
        }
      } else {
        return false;
      }
    },
    waitingForMe(data) {
      let document_user = data.document_users.find((user) => {
        if (
          user &&
          user.email &&
          this.getAuthenticatedUser &&
          this.getAuthenticatedUser.email
        ) {
          return user.email == this.getAuthenticatedUser.email;
        }
      });
      if (
        document_user &&
        document_user.sent_status &&
        document_user.user_type == "CC" &&
        !document_user.document_filling_status
      ) {
        return false;
      } else if (
        document_user &&
        document_user.sent_status &&
        !document_user.document_filling_status
      ) {
        return true;
      } else {
        return false;
      }
    },
    isfilledOrNot(doc) {
      if (
        doc &&
        doc.document_status &&
        doc.configurable_document_data &&
        doc.document_users
      ) {
        if (
          doc.document_status != "DECLINED" &&
          doc.document_status != "COMPLETED" &&
          doc.document_status != "DRAFT" &&
          doc.document_status != "ARCHIVED" &&
          doc.document_status != "VOIDED"
        ) {
          let completedUser = doc.document_users.find(
            (e) => e.type != "SENDER" && e.user_type != "CC"
          );
          if (completedUser) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
        // let fields = doc.configurable_document_data.pages[0].fields.filter(
        //   (el) => {
        //     if (
        //       el.filled_by &&
        //       el.filled_by != "SENDER" &&
        //       el.type != "my-signature" &&
        //       el.type != "MY_INITIAL"
        //     ) {
        //       return el;
        //     }
        //   }
        // );
        // let fields_data = doc.document_data;
        // let filled_fields = fields.filter((el) => {
        //   return fields_data[el.key].length;
        // });
        // if (
        //   filled_fields.length &&
        //   doc.document_status != "DECLINED" &&
        //   doc.document_status != "COMPLETED" &&
        //   doc.document_status != "ARCHIVED"
        // ) {
        //   return false;
        // } else {
        //   return true;
        // }
      } else {
        return false;
      }
    },
    async EditVoidDocument(data, id) {
      if (!this.checkPermissionForDocument(data, "editDraft")) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied. Please contact owner for edit draft permission to clone document.",
        });
      } else {
        try {
          await this.$store.dispatch("documents/EditVoidDocument", id);
          if (this.getCloneVoidDocStatus && this.getCloneVoidDocData) {
            let new_id =
              this.getCloneVoidDocData.data &&
              this.getCloneVoidDocData.data._id;
            this.$router.push({
              name: "employee-documents-custom-document-edit",
              params: {
                employee_document_id: new_id,
              },
            });
            successNotification("Document cloned successfully");
          } else {
            this.$notify({
              title: "Error",
              message: "Error in cloning document",
              type: "error",
            });
          }
        } catch (err) {
          console.log(err);
        }
      }
    },
    async correctDocument(data, id) {
      if (!this.checkPermissionForDocument(data, "editDraft")) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied. Please contact owner for edit draft permission to correct document.",
        });
      } else {
        try {
          await this.$store.dispatch("documents/correctDocument", id);
          if (this.getDocumentCorrectStatus) {
            successNotification("Document is corrected successfully");
            this.$router.push({
              name: "employee-documents-custom-document-edit",
              params: {
                employee_document_id: id,
              },
            });
          } else {
            let error_message = this.getDocumentCorrectErrors
              ? this.getDocumentCorrectErrors
              : "Error while correcting the document";
            this.$notify.error({
              title: "Error",
              message: error_message,
            });
            this.$emit("reloadDashboardStats", true);
            this.fetchDocuments();
          }
        } catch (err) {
          console.log(err);
          console.log(err.status_code);
        }
      }
    },
    async originalDownload(data) {
      console.log(data);
      let urls = [],
        names = [];
      if (
        data?.company_document_data?.document_original_download_urls?.length
      ) {
        urls = data?.company_document_data?.document_original_download_urls;
        names = data?.company_document_data?.document_names?.length
          ? data.company_document_data.document_names
          : [data.title];
      } else if (
        data?.configurable_document_data?.document_upload_urls?.length
      ) {
        urls = data?.configurable_document_data?.document_upload_urls;
        names = data?.configurable_document_data?.document_names?.length
          ? data.configurable_document_data.document_names
          : [data.title];
      } else {
        urls = [data.configurable_document_data.document_upload_url];
        names = [data.title];
      }
      this.configureLoading = true;
      this.configureLoadingMessage = "Document(s) Downloading....";

      /** MIXINS */
      await this.downloadFilesFromS3Paths(urls, names);
      this.configureLoading = false;
    },
    async onDownload(data) {
      let doc = data.document_completed_url
        ? data.document_completed_url
        : data.configurable_document_data.document_upload_url;

      this.configureLoading = true;
      this.configureLoadingMessage = "Document(s) Downloading....";

      /** MIXINS */
      await this.downloadFilesFromS3Paths([doc], [data.title]);
      this.configureLoading = false;
    },
    async saveAsTemplate(doc) {
      try {
        // params.docSett = setttng
        let params = {
          configurable_document_id: doc.configurable_document_id,
          company_document_id: doc.company_document_id,
          employee_document_id: doc._id,
          name: doc.name,
        };
        console.log("save as template params", params);
        await this.$store.dispatch("documents/saveAsTemplate", params);
        console.log(this.getDocumentSaveAsTemplateData);
        if (this.getDocumentSaveAsTemplate) {
          this.$notify({
            title: "Success",
            message: this.getDocumentSaveAsTemplateData.message,
            type: "success",
          });
        } else {
          this.$notify({
            title: "Error",
            message: this.getDocumentSaveAsTemplateData.message,
            type: "error",
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    getWaitingUser(doc) {
      if (doc.enforce_signature_order) {
        let currentUser = "";
        doc.document_users.forEach((user) => {
          if (doc.configurable_document_data) {
            if (
              doc.configurable_document_data.ck_editor_content === "" ||
              (doc.configurable_document_data.ck_editor_content === null &&
                user.fields_required &&
                user.sent_status &&
                !user.document_filling_status) ||
              (user.sent_status &&
                user.has_approval_access &&
                !user.approval_status)
            ) {
              currentUser = user.first_name;
            } else {
              if (
                (user.sent_status && !user.document_filling_status) ||
                (user.sent_status &&
                  user.has_approval_access &&
                  !user.approval_status)
              ) {
                currentUser = user.first_name;
              }
            }
          }
        });
        let result = "Waiting for " + currentUser;
        return result;
      } else {
        let currentUserSet = new Set();
        doc.document_users.forEach((user) => {
          if (doc.configurable_document_data) {
            if (
              doc.configurable_document_data.ck_editor_content === "" ||
              (doc.configurable_document_data.ck_editor_content === null &&
                user.fields_required &&
                user.sent_status &&
                !user.document_filling_status) ||
              (user.sent_status &&
                user.has_approval_access &&
                !user.approval_status)
            ) {
              currentUserSet.add(user.first_name);
            } else {
              if (
                (user.sent_status && !user.document_filling_status) ||
                (user.sent_status &&
                  user.has_approval_access &&
                  !user.approval_status)
              ) {
                currentUserSet.add(user.first_name);
              }
            }
          }
        });
        let currentUser = Array.from(currentUserSet).join(", ");
        let result = "Waiting for " + currentUser;
        return result;
      }
    },
    getCurrentRequestedUser(doc) {
      if (doc.enforce_signature_order) {
        let sent_date = "";
        doc.document_users.forEach((user) => {
          if (
            (doc.configurable_document_data &&
              doc.configurable_document_data.ck_editor_content === "") ||
            (doc.configurable_document_data.ck_editor_content === null &&
              user.fields_required &&
              user.sent_status &&
              !user.document_filling_status) ||
            (user.sent_status &&
              user.has_approval_access &&
              !user.approval_status)
          ) {
            sent_date = user.sent_on;
          } else if (
            (doc.configurable_document_data &&
              doc.configurable_document_data.ck_editor_content !== "") ||
            (doc.configurable_document_data.ck_editor_content !== null &&
              user.sent_status &&
              !user.document_filling_status) ||
            (user.sent_status &&
              user.has_approval_access &&
              !user.approval_status)
          ) {
            sent_date = user.sent_on;
          }
        });
        const format = store.getters["auth/getDefaultDateFormat"]
          ? store.getters["auth/getDefaultDateFormat"] + " HH:mm A"
          : "MM-DD-YYYY HH:mm A";
        let result = "Sent On " + moment(sent_date).format(format);
        return result;
      } else {
        let sent_date = "";
        doc.document_users.find((user) => {
          if (
            (doc.configurable_document_data &&
              doc.configurable_document_data.ck_editor_content === "") ||
            (doc.configurable_document_data.ck_editor_content === null &&
              user.fields_required &&
              user.sent_status &&
              !user.document_filling_status) ||
            (user.sent_status &&
              user.has_approval_access &&
              !user.approval_status)
          ) {
            sent_date = user.sent_on;
          } else if (
            (doc.configurable_document_data &&
              doc.configurable_document_data.ck_editor_content !== "") ||
            (doc.configurable_document_data.ck_editor_content !== null &&
              user.sent_status &&
              !user.document_filling_status) ||
            (user.sent_status &&
              user.has_approval_access &&
              !user.approval_status)
          ) {
            sent_date = user.sent_on;
          }
        });
        const format = store.getters["auth/getDefaultDateFormat"]
          ? store.getters["auth/getDefaultDateFormat"] + " HH:mm A"
          : "MM-DD-YYYY HH:mm A";
        let result = "Sent On " + moment(sent_date).format(format);
        return result;
      }
    },
    getDocSignatureStatus(percentage) {
      return percentage === 100 ? "Full" : `${percentage}%`;
    },
    getSignatureStatus(doc) {
      let total_sign_users = 0;
      let completed_sign_users = 0;
      doc.document_users.forEach((user) => {
        if (
          user.fields_required ||
          user.has_approval_access ||
          (doc?.configurable_document_data?.ck_editor_content &&
            doc.configurable_document_data?.ck_editor_content.length) ||
          doc.configurable_document_data.ck_editor_content !== null
        ) {
          total_sign_users++;

          if (user.document_filling_status) {
            completed_sign_users++;
          }
        }
      });
      if (total_sign_users == 0) {
        return 0;
      } else {
        this.nodoc = total_sign_users;
        this.nonsig = completed_sign_users;
        return (completed_sign_users / total_sign_users) * 100;
      }
    },

    getdoument(doc) {
      let tousers = "";
      let total_sign_users = 0;
      let completed_sign_users = 0;
      doc.document_users.forEach((user) => {
        if (
          user.fields_required ||
          user.has_approval_access ||
          (doc?.configurable_document_data?.ck_editor_content &&
            doc.configurable_document_data?.ck_editor_content.length) ||
          doc.configurable_document_data.ck_editor_content !== null
        ) {
          total_sign_users++;

          if (user.document_filling_status) {
            completed_sign_users++;
          }
        }
      });
      tousers += completed_sign_users + "/" + total_sign_users;
      return tousers;
    },

    format(percentage) {
      let per = percentage === 100 ? "" : `` || percentage != 100 ? "" : ``;
      console.log(per);
      let doc = "";
      return doc;
    },

    getDocumentUsers(doc) {
      let users = "";
      let last_user = doc.document_users.length - 1;
      let isDocOwner = false;
      doc.document_users.forEach((user) => {
        if (
          user &&
          user.email &&
          this.getAuthenticatedUser &&
          this.getAuthenticatedUser.email &&
          user.email == this.getAuthenticatedUser.email &&
          user.type == "SENDER"
        ) {
          isDocOwner = true;
        }
      });
      if (isDocOwner) {
        users += "To: ";
        doc.document_users.forEach((user, i) => {
          if (user.type == "RECEIVER") {
            let sep = ",";
            if (i == last_user) {
              sep = " ";
            }
            if (!user.first_name) {
              users = "";
              users += "Users not assigned";
            } else if (user.company_name && user.type == "RECEIVER") {
              users +=
                user.first_name +
                " " +
                user.last_name +
                ", " +
                "Company:" +
                user.company_name;
            } else {
              users += user.first_name + " " + user.last_name + sep;
            }
          } else if (doc.document_users.length == 1) {
            users += "Only me";
          }
        });
      } else {
        users += "From: ";
        doc.document_users.forEach((user) => {
          if (user.type == "SENDER") {
            if (user.company_name) {
              users +=
                user.first_name +
                " " +
                user.last_name +
                " " +
                user.company_name;
            } else {
              users += user.first_name + " " + user.last_name;
            }
          }
        });
      }
      return users;
    },

    getDocumentUsersTO(doc) {
      let tousers = "";
      let last_user = doc.document_users.length - 1;
      doc.document_users.forEach((user, i) => {
        if (user.type === "RECEIVER") {
          let sep = ",";
          if (i == last_user) {
            sep = "  ";
          }
          if (user.first_name) {
            if (
              user &&
              user.email &&
              this.getAuthenticatedUser &&
              this.getAuthenticatedUser.email &&
              user.email != this.getAuthenticatedUser.email
            ) {
              tousers += user.first_name + sep;
            }
          }
        }
      });
      return tousers;
    },

    getDocumentTitle(title) {
      if (title.length > 20) {
        return title.slice(0, 20) + "...";
      }
      return title;
    },
    async sortChange(a) {
      this.order_by = a.prop;
      this.order_type = a.order == "ascending" ? "asc" : "desc";
      this.fetchDocuments();
      if (a.order == null) {
        this.order_by = null;
        this.order_type = null;
        this.fetchDocuments();
      }
    },
    async fetchDocuments() {
      console.log(this.selected_company_user, this.search_string);
      this.authid = this.getAuthenticatedUser._id;
      try {
        this.configureLoading = true;
        this.configureLoadingMessage = "Fetching Documents....";

        let params = {
          limit: parseInt(this.pageSize),
          page: parseInt(this.page),
          //get_all:true
        };
        if (this.$route && this.$route.query && this.$route.query.type) {
          params.document_status = this.$route.query.type;
        }
        if (this.selectedStatus) {
          params.document_status = this.selectedStatus;
        }
        if (this.search_string) {
          params.search_string = this.search_string;
        }
        if (
          this.$route &&
          this.$route.query &&
          this.$route.query.type &&
          this.$route.query.type == "company-documents"
        ) {
          if (this.selected_company_user) {
            params.selected_company_user = this.selected_company_user;
          }
        }

        if (this.order_by) {
          params.order_by = this.order_by;
        }
        if (this.order_type) {
          params.order_type = this.order_type;
        }
        if (this.$route && this.$route.query && this.$route.query.type) {
          params.document_status = this.$route.query.type;
        }
        await this.$store.dispatch("documents/fetchAllDocuments", params);
        if (
          this.$route &&
          this.$route.query &&
          this.$route.query.type &&
          this.$route.query.type == "company-documents"
        ) {
          params.company_id =
            this.getActiveWorkspace && this.getActiveWorkspace.company_id;
          await this.$store.dispatch("documents/fetchAllCompanyUsers", params);
        }
        this.documents = this.getAllDocuments.data;
        this.total = this.getAllDocuments.total;
        this.configureLoading = false;
      } catch (err) {
        this.configureLoading = false;
      }
    },
    updatedDateSort: function (a, b) {
      if (a.updated_at < b.updated_at) return -1;
      if (a.updated_at > b.updated_at) return 1;
      return 0;
    },
    createdDateSort: function (a, b) {
      if (a.created_at < b.created_at) return -1;
      if (a.created_at > b.created_at) return 1;
      return 0;
    },
    async alertvoided(doc_id) {
      if (confirm) {
        this.$confirm(
          "Are you sure you want to Void the document?",
          "Warning",
          {
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "warning",
          }
        ).then(() => {
          this.DocumentVoid(doc_id);
        });
      }
    },
    async DocumentVoid(doc_id) {
      let document_id = doc_id;
      await this.$store.dispatch("documents/voidDocument", { document_id });
      if (this.getDocumentVoidStatus) {
        successNotification("Document Voided successfully");
        this.$emit("reloadDashboardStats", true);
        this.fetchDocuments();
      } else {
        errorNotification("Error in void document");
      }
    },

    onEdit(document) {
      let isCompanyUser = false;
      let hasUser = false;
      if (this.getActiveWorkspace && this.getActiveWorkspace.company_id) {
        isCompanyUser =
          document &&
          document.company_id &&
          this.getActiveWorkspace.company_id.toString() ==
            document.company_id.toString();
      }
      if (this.getAuthenticatedUser) {
        hasUser =
          document &&
          document.document_users &&
          document.document_users.find(
            (u) => u.email == this.getAuthenticatedUser.email
          );
      }
      if (document.document_status == "DRAFT") {
        if (
          hasUser ||
          ((this.checkPermissionForDocument(document, "viewDraft") ||
            this.checkPermissionForDocument(document, "editDraft")) &&
            isCompanyUser)
        ) {
          // let newWindow = open(
          //   `/emp-documents/custom/${document._id}/edit`,
          //   "example",
          //   "width=1500,height=900"
          // );
          // newWindow.focus();
          // newWindow.onload = function () {};
          this.$router.push({
            name: "employee-documents-custom-document-edit",
            params: {
              employee_document_id: document._id,
            },
          });
        } else {
          this.errorNotify(
            "Permission denied for edit draft document. Please contact owner"
          );
        }
      } else {
        if (document.workflow_data_id) {
          this.$router.push({
            name: "employee-documents-custom-document",
            params: {
              employee_document_id: document._id,
            },
            query: {
              workflow_id: document.workflow_data_id,
            },
          });
        } else {
          let newWindow = open(
            `/emp-documents/custom/${document._id}`,
            "example",
            "width=1500,height=900"
          );
          newWindow.focus();
          newWindow.onload = function () {};
          // this.$router.push({
          //   name: "employee-documents-custom-document",
          //   params: {
          //     employee_document_id: document._id,
          //   },
          // });
        }
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.fetchDocuments();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.fetchDocuments();
    },
    async loadData(data) {
      if (data && data.type != "init") {
        this.pageSize = data.pageSize;
        this.page = data.page;
        await this.fetchDocuments();
      }
    },
    openNameModal(id, name, action) {
      try {
        this.$store.commit("documents/setDuplicateDocumentErrors", null, {
          root: true,
        });
        this.modalData.document_id = id;
        this.modalData.name = name;
        this.modalData.action = action;
        this.documentActionModal = true;
      } catch (err) {
        console.log(err);
      }
    },
    handleDocAction() {
      if (this.modalData.action == "duplicate") {
        this.duplicateDocument();
        this.$emit("reloadDashboardStats", true);
      } else {
        this.renameDocument();
      }
    },
    async duplicateDocument() {
      this.modalLoading = true;
      await this.$store.dispatch("documents/duplicateDocument", this.modalData);
      this.modalLoading = false;
      if (this.getDuplicateDocumentStatus) {
        successNotification("Document duplicated successfully");
        this.documentActionModal = false;
        this.fetchDocuments();
      } else {
        errorNotification("Error in duplicating document");
      }
    },
    async renameDocument() {
      this.modalLoading = true;
      await this.$store.dispatch("documents/renameDocument", this.modalData);
      this.modalLoading = false;
      if (this.getRenameDocumentStatus) {
        successNotification("Document title updated successfully");
        this.documentActionModal = false;
        this.fetchDocuments();
      } else {
        errorNotification("Error in updating document title");
      }
    },

    async settings(_id) {
      this.docSettingsVisible = true;
      this.configurableDocumentId = _id;
    },

    async permanentDocument(document_id) {
      this.$confirm(
        "Are you sure Want To Permanently Delete.Complete Data Of This Document ?"
      )
        .then(async () => {
          this.loading = true;
          await this.$store.dispatch("documents/parmanentdelete", {
            document_id,
          });
          this.loading = false;
          if (this.getArchiveDocumentStatus) {
            successNotification("Document Deleted Permanently");
            this.$emit("reloadDashboardStats", true);
            this.fetchDocuments();
          } else {
            errorNotification("Error in Permanent document");
          }
        })
        .catch(() => {});
    },

    async archiveDocumentundo(document_id) {
      this.$confirm("Are you sure to Undo the document?")
        .then(async () => {
          this.loading = true;
          await this.$store.dispatch("documents/undoDocument", {
            document_id,
          });
          this.loading = false;
          if (this.getArchiveDocumentStatus) {
            successNotification("Document Undo successfully");
            this.$emit("reloadDashboardStats", true);
            this.fetchDocuments();
          } else {
            errorNotification("Error in Undo document");
          }
        })
        .catch(() => {});
    },
    async onVoided(row) {
      this.$confirm("Are you sure to Void the document?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.deleteDocument(row);
      });
    },

    async archiveDocument(document_id) {
      this.$confirm("Are you sure to delete the document?")
        .then(async () => {
          this.is_documentDelete = true;
          this.configureLoading = true;
          this.configureLoadingMessage = "Deleting Document....";

          await this.$store.dispatch("documents/archiveDocument", {
            document_id,
          });
          this.loading = false;
          this.is_documentDelete = false;
          this.isDocumentDelete = false;
          if (this.getArchiveDocumentStatus) {
            successNotification("Document deleted successfully");
            this.$emit("reloadDashboardStats", true);
            this.fetchDocuments();
          } else {
            errorNotification("Error in deleting document");
          }
        })
        .catch(() => {});
    },

    // checkPermissionForDocument(documentInfo, permission){
    //   if(this.getActiveWorkspace && this.getActiveWorkspace.plan_type == 'INDIVIDUAL'){
    //     let isCreator = documentInfo.created_by.toString() == this.getAuthenticatedUser._id.toString();
    //     if(isCreator){
    //       return true
    //      }
    //   }else{
    //   let hasUser = documentInfo.document_users.findIndex((cu)=>(cu.email == this.getAuthenticatedUser.email));
    //   let isCreator = documentInfo.created_by.toString() == this.getAuthenticatedUser._id.toString();
    //   // console.log(hasUser, isCreator);
    //   if(this.getUserType.name && this.getUserType.name == 'OWNER'){
    //     return true;
    //   }else if((hasUser != -1 || isCreator) && this.getUserType && this.getUserType.permissionSet){
    //     return this.getUserType.permissionSet.includes(permission)?true:false;
    //   }else if(this.getUserType && this.getUserType.permissionSet){
    //     return this.getUserType.permissionSet.includes(permission)?true:false;
    //   }else{
    //     return false;
    //   }
    // }
    // }
    checkPermissions(permission) {
      if (
        this.getUserType &&
        this.getUserType.name &&
        this.getUserType.name == "OWNER"
      ) {
        return true;
      }
      if (this.getUserType && this.getUserType.permissionSet) {
        return this.getUserType.permissionSet.includes(permission)
          ? true
          : false;
      } else {
        return true;
      }
    },
    async downloadToSharepoint(scopeData) {
      try {
        const registeredUser = await axios.get("/get-app-integration-data");
        if (registeredUser.data.success == true) {
          this.configureLoading = true;
          const response = await axios.post(
            "/documents/getSharePointAccessToken"
          );
          this.appData = registeredUser.data.data.app_data;
          if (registeredUser.data.data.app_settings.is_default_download) {
            this.uploadFileToSharePoint(
              response.data.data.access_token,
              scopeData,
              registeredUser.data.data.app_settings.default_folder_path
            );
            return (this.configureLoading = false);
          }
          this.sharepointAccessToken = response.data.data.access_token;
          this.sharepointDataDialog = true;
          this.fileScope = scopeData;
          this.path = "";
          await this.fetchAllDocumentsFromSharePoint(
            this.sharepointAccessToken
          );
          this.configureLoading = false;
        } else {
          this.redirectDialog = true;
        }
      } catch (error) {
        this.configureLoading = false;
        this.sharepointDataDialog = false;
        this.$notify.error({
          title: "Error",
          message: "An Error Occurred during Authentication",
        });
      }
    },
    async fetchAllDocumentsFromSharePoint(accessToken, path = "") {
      try {
        this.sharepointData = [];
        let apiUrl = `${this.appData.siteUrl}/sites/${this.appData.siteName}/_api/Web/GetFolderByServerRelativePath(decodedurl='/sites/${this.appData.siteName}/Shared%20Documents')?$expand=Folders,Files`;
        if (path) {
          apiUrl = `${this.appData.siteUrl}/sites/${this.appData.siteName}/_api/Web/GetFolderByServerRelativePath(decodedurl='/sites/${this.appData.siteName}/Shared%20Documents${path}')?$expand=Folders,Files`;
        }
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
        });
        const folderData = response.data;
        const folders = folderData.Folders;
        folders.forEach((folder) => {
          if (folder.Name !== "Forms") {
            const document = {
              file: folder.Name,
              lastModified: folder.TimeLastModified,
              fileUrl: folder.ServerRelativeUrl,
              type: "folder",
            };
            this.sharepointData.push(document);
          }
        });
      } catch (err) {
        this.$notify.error({
          title: "Error",
          message: "Error Fetching Documents",
        });
      }
    },
    async uploadFileToSharePoint(accessToken, data, defaultPath = "") {
      try {
        this.sharepointDataDialog = false;
        let doc = data.document_completed_url
          ? data.document_completed_url
          : data.configurable_document_data.document_upload_url;

        let urls = this.getDownloadFilesURLFromS3Paths([doc]);
        const S3BucketResponse = await fetch(urls[0]);
        const documentData = await S3BucketResponse.blob();
        let file_name = data.title.split(".")[0];
        let libraryName = `/sites/${this.appData.siteName}/Shared%20Documents`;
        if (this.path !== "") {
          libraryName = `/sites/${this.appData.siteName}/Shared%20Documents/${this.path}`;
        }
        if (defaultPath) {
          libraryName = `/sites/${this.appData.siteName}/Shared%20Documents${defaultPath}`;
        }
        const uploadUrl = `${this.appData.siteUrl}/sites/${this.appData.siteName}/_api/web/GetFolderByServerRelativeUrl('${libraryName}')/Files/add(url='${file_name}.pdf', overwrite=true)`;
        await axios.post(uploadUrl, documentData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/octet-stream",
          },
        });
        this.path = "";
        this.$notify.success({
          title: "Success",
          message: `${file_name} Uploaded successfully`,
        });
      } catch (err) {
        this.$notify.error({
          title: "Error",
          message: "An Error Occurred during the file upload",
        });
      }
    },

    async getOneDriveToken() {
      const clientId = appConfig.AZURE_APP_ID;
      const redirectUri = `${appConfig.APP_URL}/dashboard`;
      const scope = "Files.ReadWrite.All";
      const responseType = "token";

      const authEndpoint = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize`;
      const authUrl = `${authEndpoint}?client_id=${clientId}&redirect_uri=${encodeURIComponent(
        redirectUri
      )}&scope=${encodeURIComponent(scope)}&response_type=${responseType}`;

      const popup = window.open(
        authUrl,
        "OneDrive Login",
        "width=600,height=600"
      );

      return new Promise((resolve, reject) => {
        const intervalId = setInterval(() => {
          if (!popup || popup.closed || popup.closed === undefined) {
            clearInterval(intervalId);
            reject(new Error("Popup window was closed"));
          }
          try {
            if (popup.location.href.indexOf(redirectUri) === 0) {
              clearInterval(intervalId);
              const params = new URLSearchParams(
                popup.location.hash.substring(1)
              );
              const accessToken = params.get("access_token");
              const expiresIn = params.get("expires_in");
              if (accessToken) {
                resolve({ accessToken, expiresIn });
              } else {
                reject(new Error("Access token not found"));
              }
              popup.close();
            }
          } catch (error) {
            console.log(error.message);
          }
        }, 100);
      });
    },
    async downloadToOneDrive(data) {
      try {
        if (
          !this.oneDriveData.expiresIn ||
          !this.oneDriveData.expiresIn ||
          new Date().toISOString() > this.oneDriveData.expiresIn
        ) {
          const Token = await this.getOneDriveToken();
          this.oneDriveData.accessToken = Token.accessToken;
          const currentTime = new Date().toISOString();
          const expirationTime = new Date(
            new Date(currentTime).getTime() +
              (parseInt(Token.expiresIn) - 2) * 1000
          ).toISOString();
          this.oneDriveData.expiresIn = expirationTime;

          const token = {
            accessToken: Token.accessToken,
            expiresIn: expirationTime,
          };
          localStorage.setItem("oneDriveToken", JSON.stringify(token));
        }
        const doc = data.document_completed_url
          ? data.document_completed_url
          : data.configurable_document_data.document_upload_url;

        let urlSliptArray = doc.split("/");
        let tempUrl = "";
        if (urlSliptArray[1] != process.env.VUE_APP_APP_TYPE) {
          tempUrl = `/${process.env.VUE_APP_APP_TYPE}${doc}`;
        } else {
          tempUrl = doc;
        }
        console.log(
          "-=-=process.env.VUE_APP_APP_TYPE-=--",
          process.env.VUE_APP_APP_TYPE,
          "basedf",
          this.basePdfdownloadUrl,
          "temp url",
          tempUrl
        );
        let url = this.basePdfdownloadUrl + tempUrl;
        console.log("Downloading file from:", url);
        const S3BucketResponse = await fetch(url);
        const documentData = await S3BucketResponse.blob();
        let file_name = data.title.split(".")[0];

        const uploadUrl = `https://graph.microsoft.com/v1.0/me/drive/root:/${file_name}.pdf:/content`;

        const headers = {
          Authorization: `Bearer ${this.oneDriveData.accessToken}`,
          "Content-Type": "application/octet-stream",
        };

        await axios.put(uploadUrl, documentData, { headers });

        this.$notify.success({
          title: "Success",
          message: `${file_name} Uploaded successfully`,
        });
      } catch (error) {
        this.$notify.error({
          title: "Error",
          message: "An Error Occurred during the file upload",
        });
      }
    },
    logoutOneDrive() {
      this.oneDriveData = {
        accessToken: "",
        expiresIn: 0,
      };
      this.$notify.success({
        title: "Success",
        message: "Logged out of oneDrive",
      });
    },
    // async downloadToGoogleDrive(data){
    //   let doc = data.document_completed_url
    //               ? data.document_completed_url
    //               : data.configurable_document_data.document_upload_url;
    //     let urlSliptArray = doc.split("/");
    //     let tempUrl = "";
    //     if (urlSliptArray[1] != process.env.VUE_APP_APP_TYPE) {
    //         tempUrl = `/${process.env.VUE_APP_APP_TYPE}${doc}`;
    //     } else {
    //        tempUrl = doc;
    //     }
    //   let fileUrl = this.basePdfdownloadUrl + tempUrl;
    //   const S3BucketResponse = await fetch(fileUrl);
    //   const documentData = await S3BucketResponse.blob();
    //   let fileName = data.title.split(".")[0];

    // //   const client_id='131012958263-bmg7s9v1eossb7hh0rn44v87bv2vb1n3.apps.googleusercontent.com'
    // //         const client_secret='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'
    // //         const redirect_uris=['http://localhost:8080/dashboard','https://dev.esigns.io/dashboard']
    // //         const oAuth2Client = new google.auth.OAuth2(
    // //             client_id,
    // //             client_secret,
    // //             redirect_uris[0]
    // //         );

    // //   const drive = google.drive({ version: 'v3', auth: client });

    // //   const fileMetadata = {
    // //   name: fileName,
    // //   };

    // //   const media = {
    // //   mimeType: documentData.type,
    // //   body: documentData,
    // //   };

    // //   const response = await drive.files.create({
    // //   resource: fileMetadata,
    // //   media: media,
    // // });
    // },

    handleSharepointSizeChange(newSize) {
      this.dialogTablePageSize = newSize;
      this.dialogTableCurrentPage = 1; // Reset current page to 1
    },
    handleCurrentPageChange(newPage) {
      this.dialogTableCurrentPage = newPage;
    },
    handlePath(folderName) {
      this.path = `${this.path}/${folderName}`;
      this.fetchAllDocumentsFromSharePoint(
        this.sharepointAccessToken,
        this.path
      );
    },
    handleBreadcrumbClick(folder) {
      const breadcrumbIndex = this.pathArray.indexOf(folder);
      if (breadcrumbIndex !== -1) {
        this.path = this.pathArray.slice(0, breadcrumbIndex + 1).join("/");
        this.fetchAllDocumentsFromSharePoint(
          this.sharepointAccessToken,
          this.path
        );
      } else if (folder === "") {
        this.path = "";
        this.fetchAllDocumentsFromSharePoint(this.sharepointAccessToken);
      } else {
        const lastIndex = this.path.lastIndexOf("/");
        if (lastIndex !== -1) {
          this.path = this.path.substr(0, lastIndex);
          this.fetchAllDocumentsFromSharePoint(
            this.sharepointAccessToken,
            this.path
          );
        }
      }
    },
    async enableDownloadSingleDocument(data) {
      this.downloadSingleDocument = true;
      this.currentEmployeeDocumentId = data._id;
      this.SingleDocumentDownloadLoading = true;
      await this.fetchEmployeDocumentData();
      let allUrlsData = await this.getAllCompletedDocuments();
      this.allCompletedDocuments = allUrlsData;
      this.SingleDocumentDownloadLoading = false;
    },
    getAllCompletedDocuments() {
      let allUrlsData = [];
      if (
        this.getSingleDocumentData &&
        this.getSingleDocumentData.data &&
        this.getSingleDocumentData.data.document_status == "COMPLETED"
      ) {
        let allUrls =
          this.getSingleDocumentData.data.document_completed_urls &&
          this.getSingleDocumentData.data.document_completed_urls.length
            ? this.getSingleDocumentData.data.document_completed_urls
            : [this.getSingleDocumentData.data.document_completed_url];
        let names =
          this.getSingleDocumentData.data.document_names &&
          this.getSingleDocumentData.data.document_names.length &&
          this.getSingleDocumentData.data.document_names.length > 1
            ? this.getSingleDocumentData.data.document_names
            : [this.getSingleDocumentData.data.name || "document.pdf"];
        let title =
          this.getSingleDocumentData.data.name ||
          this.getSingleDocumentData.data.title;
        allUrls.forEach((el, i) => {
          if (names.length) {
            allUrlsData.push({
              name: names[i],
              mainTitle: title,
              url: el,
            });
          }
        });
        this.getSingleDocumentData.data.printed_doc_url.forEach((el, index) => {
          if (el) {
            let obj = {
              name: "Signed on paper" + " " + index + 1 + ".pdf",
              mainTitle: "",
              url: el,
            };
            allUrlsData.push(obj);
          }
        });
      }
      return allUrlsData;
    },
    async fetchEmployeDocumentData() {
      try {
        await this.$store.dispatch("documents/fetchDocumentDataById", {
          document_id: this.currentEmployeeDocumentId,
        });
        if (
          this.getAuthenticatedUser &&
          this.getAuthenticatedUser.email &&
          this.getSingleDocumentData &&
          this.getSingleDocumentData.data &&
          this.getSingleDocumentData.data.document_users
        ) {
          if (this.getSingleDocumentData.data.company_id) {
            this.logoThreeLoading = true;
            // await this.$store.dispatch(
            //   "company/fetchCompanyLogo",
            //   this.getSingleDocumentData.data.company_id
            // );
            this.logoThreeLoading = false;
          }
          const documentuser =
            this.getSingleDocumentData.data.document_users.find(
              (user) => user && user.email == this.getAuthenticatedUser.email
            );
          if (documentuser && documentuser.email) {
            this.is_forbidden_doc = false;
          } else {
            if (
              this.getActiveWorkspace &&
              this.getActiveWorkspace.company_id ==
                this.getSingleDocumentData.data.company_id
            ) {
              this.isCompanyDocument = true;
              this.is_forbidden_doc = false;
            } else {
              this.is_forbidden_doc = true;
            }
          }
        } else {
          if (
            this.getDocumentSendErrors &&
            this.getDocumentSendErrors.critical_error
          ) {
            this.is_forbidden_doc = true;
          }
        }
      } catch (error) {
        if (error) {
          this.is_forbidden_doc = true;
        }
      }
    },
    async downloadCompletedDocumentForUser(doc) {
      this.SingleDocumentDownloadLoading = true;
      if (doc.url) {
        /** MIXIN */
        await this.downloadFilesFromS3Paths(
          [doc.url],
          [doc.name],
          [doc.mainTitle]
        );
      } else {
        this.isRedownloading = true;

        this.$message({
          message: "Generating your document...",
          type: "warning",
        });
        this.downloadCompletedDocument();
      }
      this.SingleDocumentDownloadLoading = false;
    },
  },
  watch: {
    selected_document() {
      this.$emit("deleteEvent", {
        data: this.selected_document,
      });
    },
    selectedDocStatus: function (val) {
      if (val !== this.selectedStatus) {
        this.page = 1;
        this.selectedStatus = val;
        if (
          this.$route &&
          this.$route.query &&
          this.$route.query.type != this.selectedStatus
        ) {
          let routeQuery = { type: this.selectedStatus };
          this.$router.replace({ query: routeQuery });
        }
        this.fetchDocuments();
      }
    },
    search_string: function (val) {
      this.search_string = val;
      this.fetchDocuments();
    },
    selected_company_user: function (val) {
      this.selected_company_user = val;
      this.fetchDocuments();
    },
  },
  beforeDestroy() {
    this.$store.commit("documents/setAllDocuments", null, { root: true });
    this.$store.commit("documents/setRenameDocumentStatus", null, {
      root: true,
    });
    this.$store.commit("documents/setDuplicateDocumentStatus", null, {
      root: true,
    });
    this.$store.commit("documents/setArchiveDocumentStatus", null, {
      root: true,
    });
    this.$store.commit("documents/setDuplicateDocumentErrors", null, {
      root: true,
    });
    this.$store.commit("documents/setDocumentVoidStatus", null, { root: true });
    this.$store.commit("documents/setResendDocumentStatus", null, {
      root: true,
    });
    this.$store.commit("documents/setDocumentSaveAsTemplate", null, {
      root: true,
    });
    this.$store.commit("documents/setDocumentSaveAsTemplate", null, {
      root: true,
    });
    this.$store.commit("documents/setDocumentCorrectStatus", null, {
      root: true,
    });
    this.$store.commit("documents/setDocumentCorrectErrors", null, {
      root: true,
    });
    this.$store.commit("documents/setCloneVoidDocStatus", null, { root: true });
    this.$store.commit("documents/setCloneVoidDocData", null, { root: true });
    this.$store.commit("documents/setUpdateDocumentUsersStatus", null, {
      root: true,
    });
    this.$store.commit("documents/setDocumentSaveAsTemplateData", null, {
      root: true,
    });
    this.$store.commit("documents/setSendCompletedDocumentStatus", null, {
      root: true,
    });
  },
};
</script>

<style lang="scss" scoped>
.el-button--danger.is-plain {
  margin-right: 10px;
  background: #fff0f7 !important;
  border-color: #ffd7ea !important;
}
.el-button--danger.is-plain:hover {
  background-color: #f754a2 !important;
}
.text-muted {
  white-space: nowrap;
  font-size: 14px;
}
.centered-item {
  display: flex;
  padding-left: 20px;
  align-items: center;
}
.sendEmailLink {
  display: flex;
  align-items: center;
  text-align: left;
}
.all-documents-view {
  .title {
    letter-spacing: 0.019em;
  }
  .text-secondary-sent {
    font-size: 10px !important;
    font-weight: 400 !important;
    line-height: 14px !important;
    text-align: left !important;
    color: #439ffc !important;
  }
  .text-secondary {
    font-size: 14px !important;
    font-weight: 400 !important;
    // line-height: 14px !important;
    text-align: left !important;
    color: #6c757d !important;
  }
  .doc-subs {
    font-size: 10px;
    margin-top: -13px;
    font-weight: 300;
    text-align: right;
    margin-right: 9%;
  }
  .doc-sub {
    font-size: 10px;
    font-weight: 300;
    line-height: 14px;
    //margin-bottom:-18px;
    text-align: left;
  }
  .doc-title {
    cursor: pointer;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    font-weight: 300;
    line-height: 14px;
    text-align: left;

    color: #6c757d !important;
  }
  .check-font {
    color: #6c757d !important;
    font-family: "Inter", sans-serif;
  }
  .circle {
    position: relative;
    padding-left: 20px;
    text-transform: capitalize;
    font-size: 14px;
    &::after {
      background: #635e5e;
      content: "";
      position: absolute;
      top: 4px;
      left: 0;
      height: 10px;
      width: 10px;
      display: inline-block;
      border-radius: 50%;
    }
    &.completed::after {
      background: #08ad36;
    }
    &.draft::after {
      background: #f19203;
    }
    &.sent::after {
      background: #2f80ed;
    }
    &.active::after {
      background: #635e5e;
    }
    &.expired::after {
      background: #f03232;
    }
  }
  .custom-pagination {
    text-align: center;
    margin-top: 5px;
  }
}
.main-image {
  .box {
    margin-left: 25%;
  }
}
.sub-image {
  width: 75px;
}
.main_container {
  width: 100vw;
  height: 100vh;
}
.box {
  margin: 20px;
  text-align: center;
  box-sizing: border-box;
  font-size: 10px;
  margin-top: 30px;
}
.outersize {
  height: 100vh;
  @media (max-width: 991.98px) {
    width: 90%;
    left: 5%;
  }
  @media (min-width: 992px) {
    width: 50%;
    left: 25%;
  }
}
.numbers {
  position: absolute;
  margin-left: -25px;
}
.arrows {
  position: absolute;
  margin-left: 120px;
  margin-top: 50px;
}
.center {
  background: #1b487e;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  align-items: center;
  text-align: center;
  margin-left: 15px;
}
.put_numbers {
  position: relative;
  top: 2px;
  color: white;
  margin: auto;
  margin-top: 20px !important;
}

#bsty {
  width: fit-content;
  background-color: #f562b3;
  border: none;
  color: white;
  text-align: center;
}
.dilog-header {
  text-align: center;
}
.headp {
  // margin-top: -35px;
  font-size: 24px;
  color: black;
}
.footer-dialog {
  text-align: center;
  margin-top: 0px;
}
.mailboxStyle {
  margin-left: 65%;
}
// .head1p {
//   margin-top: -20px;
// }
@media screen and (max-width: 1200px) {
  .box {
    width: 40%;
    height: 40%;
  }
}
.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.breadcrumb-item {
  cursor: pointer;
  color: #409eff;
}

.breadcrumb-separator {
  margin: 0 5px;
  color: #909399;
}

.folder-column {
  cursor: pointer;
}

.all-documents-view .icon-block {
  display: grid;
  grid-template-columns: 25px auto;
  gap: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 18px !important;
}

.vue-data-table-default {
  .el-table {
    .el-table__cell {
      padding: 2px 5px !important;
      min-width: 0;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      text-overflow: ellipsis;
      vertical-align: middle;
      position: relative;
      text-align: left;
      /* height: 10px; */
    }
  }
}
</style>
